<template>
  <div>
    <site-loading v-show="loading" />
    <div class="overlay"></div>
    <!-- <transition name="router-anim" enter-active-class="animated fadeIn" leave-active-class="animated fadeOut"> -->
      <router-view :connection="connection" :status="status" @handle-webhook="websocketHandler($event, value)" :active-users="activeUsers" />
    <!-- </transition> -->
    <user-cursor :users="users" />
  </div>
</template>
<style>
@import "https://cdn.jsdelivr.net/npm/animate.css@3.5.1";

body {
  z-index: 9999;
}
</style>
<script>
import sympleApi from '@/api/symple-api'
import $ from 'jquery'
import axios from 'axios'
import { ref } from 'vue'
import siteLoading from './components/shared/SiteLoading.vue'
import UserCursor from './components/shared/UserCursor.vue'

export default {
  name: 'LayoutDefault',
  data: () => {
    return {
      connection: null,
      activeUsers: [],
      users: [],
      route: '',
      x: 0,
      scrollX: 0,
      y: 0,
      scrollY: 0,
      loading: true,
      status: ''
    };
  },
  components: {
    UserCursor,
    siteLoading
  },
  setup () {
    return {
      leftDrawerOpen: ref(false)
    }
  },
  updated() {
    let self = this;
    this.setUserStatus()
    if (this.route != '/' && this.route != '/forgot-password' && this.route != '/reset-password') {
      setInterval(function(){
        self.users = []
      }, 100000);
      const currentUserImage = localStorage.getItem("currentUserImage");
      if (currentUserImage && !self.activeUsers.some(e => e.image === currentUserImage.trim())) {
        self.activeUsers.push({ type: 'Active User', image: currentUserImage.trim(), path: self.$route.path, color: localStorage.getItem("currentUserColor"), status: self.status });
      }
    }
    setTimeout(function(){
      self.loading = false
    }, 500);
  },
  created() {
    let self = this;
    window.addEventListener('beforeunload', this.removeUser)
    window.addEventListener('beforeunload', this.removeUserFieldFocus)
    this.connection = new WebSocket(this.websocket())
    this.connection.onmessage = function(event) {
      self.websocketHandler({ message: JSON.parse(event.data) });
    }
    this.setUserStatus()
  },
  mounted() {
    let self = this;
    if (this.$route.path != '/') {
      setInterval(function(){
        self.users = []
      }, 100000);
      this.activeUsers.push({ type: 'Active User', image: localStorage.getItem("currentUserImage").trim(), path: this.$route.path, color: localStorage.getItem("currentUserColor"), status: this.status });
    }
    setTimeout(function(){
      self.loading = false
    }, 500);
  },
  methods: { 
    setUserStatus(){
      let self = this;
      if (this.$route.path.includes('edit')) {
        let name;
        axios
          .get(
            `${sympleApi.sympleApiBaseURL()}properties/` +
              this.$route.params.id,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
              },
            }
          )
          .then((response) => {
            name = response.data.data.attributes.name
            self.status = `Editing ${name}`
          })
      } else if (this.$route.path.trim() === '/properties') {
        this.status = 'Viewing Properties'
      } else if (this.$route.path.trim() === '/specials') {
        this.status = 'Viewing Specials'
      } else if (this.$route.path.trim() === '/users') {
        this.status = 'Viewing Users'
      }
    },
    usersExludingCurrent() {
      return this.users.filter(x => x.image != localStorage.getItem("currentUserImage").trim())
    },
    websocket() {
      if (process.env.NODE_ENV === 'development') {
        return "ws://localhost:8080";
      } else {
        return "wss://symple.io:8080";
      }
    },
    removeUser() {
      this.connection.send(JSON.stringify({ type: 'Remove Position', image: localStorage.getItem("currentUserImage") }));
    },
    updateXY: function(){
      if (localStorage.getItem("currentUserImage")) {
        this.x = event.clientX
        this.scrollX = window.scrollX
        this.y = event.clientY
        this.scrollY = window.scrollY
        this.connection.send(JSON.stringify({ type: 'Position', x: this.x + this.scrollX, y: this.y + this.scrollY, image: localStorage.getItem("currentUserImage").trim(), path: this.$route.path, color: localStorage.getItem("currentUserColor"), status: this.status }));
      }
    },
    updateScroll: function(){
      if (localStorage.getItem("currentUserImage")) {
        this.scrollX = window.scrollX
        this.scrollY = window.scrollY
        this.connection.send(JSON.stringify({ type: 'Position', x: this.x + this.scrollX, y: this.y + this.scrollY, image: localStorage.getItem("currentUserImage").trim(), path: this.$route.path, color: localStorage.getItem("currentUserColor"), status: this.status }));
      }
    },
    websocketHandler(data) {
      let message = data.message
      let value = data.value
      let self = this;
      if ((message.type === 'Position' || message.type === 'Active User') && (message.image && message.image != '')) {
        if (message.path === self.$route.path && message.image != localStorage.getItem("currentUserImage").trim()) {
          if (this.$route.name === 'Edit') {
            if (self.users.some(e => e.image === message.image)) {
              self.users.splice(self.users.findIndex(e => e.image === message.image),1);
            }
            self.users.push(message)
          }
        }
        if (!self.activeUsers.some(e => e.image === message.image)) {
          self.activeUsers.push(message)
        } else {
          let user = self.activeUsers.filter(e => e.image === message.image)[0]
          user.status = message.status
        }
      }
      if (message.type === 'Remove Position') {
        if (self.activeUsers.some(e => e.image === message.image)) {
          self.activeUsers.splice(self.activeUsers.findIndex(e => e.image === message.image),1);
        }
        if (self.users.some(e => e.image === message.image)) {
          self.users.splice(self.users.findIndex(e => e.image === message.image),1);
        }
      }
      if (message.type === 'Redirect') {
        if (self.users.some(e => e.image === message.image)) {
          self.users.splice(self.users.findIndex(e => e.image === message.image),1);
        }
      }
      if (message.type === 'Field Changed') {
        if (self.$route.params.id === message.access_id) {
          var index = value.findIndex((updated) => { return updated.id===message.field.id })
          value[index] = message.field
        }
      }
      if (message.type === 'Get Fields') {
        if (self.$route.params.id === message.access_id && message.user != localStorage.getItem("currentUserImage").trim()) {
          this.connection.send(JSON.stringify({ type: 'Send Fields', user: message.user, fields: value, access_id: message.access_id }));
        }
      }
      if (message.type === 'Send Fields') {
        if (self.$route.params.id === message.access_id && message.user === localStorage.getItem("currentUserImage").trim()) {
          value.forEach(function(field, i){
            field.data = message.fields[i].data
            field.name = message.fields[i].name
            field.short_name = message.fields[i].short_name
          })
        }
      }
      if (message.type === 'Field Focus') {
        if (self.$route.params.id === message.access_id && message.user != localStorage.getItem("currentUserImage").trim()) {
          if (message.group === $('.list-group-item.active').find('.group-input').val()) {
            $.each($(`.q-card[data-field-id="${message.field.id}"]`), function(index, element) {
              $(element).find('.field-avatar .q-img__image').attr("src", message.user)
              $(element).find('.field-avatar').attr('style', 'display: block;')
              $(element).attr('style', `border-radius: 18px; border: 1.1px solid ${message.color} !important;`)
              $(element).find('.q-field').addClass('disabled')
            });
          }
        }
      }
      if (message.type === 'Remove Field Focus') {
        if (message.group === $('.list-group-item.active').find('.group-input').val()) {
          $.each($(`.q-card[data-field-id="${message.field.id}"]`), function(index, element) {
            $(element).find('.field-avatar').attr('style', 'display: none;')
            $(element).attr('style', 'border-radius: 18px; border: 2px solid rgb(202, 206, 250);')
            $(element).find('.q-field').removeClass('disabled')
          });
        }
      }
      if (message.type === 'Remove User Field Focus') {
        let element = $(`.q-card`).find(`.field-avatar .q-img__image[src='${message.image}']`)
        element.parents('.field-avatar').attr('style', 'display: none;')
        element.parents('.q-card').attr('style', 'border-radius: 18px; border: 2px solid rgb(202, 206, 250);')
        element.parents('.q-card').find('.q-field').removeClass('disabled')
      }
      if (message.type === 'Publish Status') {
        if (self.$route.params.id === message.access_id) {
          if (message.status === 'Published') {
            value.status = 'Recently Published';
            setTimeout(function(){
              value.status = 'Published';
            }, 5000);
          } else if (['Failed', 'Publish Error'].includes(message.status)) {
            value.status = 'Recently Failed';
            setTimeout(function(){
              value.status = 'Failed';
            }, 5000);
          } else {
            value.status = message.status;
          }
        }
      }
    },
    removeUserFieldFocus() {
      this.connection.send(JSON.stringify({ type: 'Remove User Field Focus', path: this.route, image: localStorage.getItem("currentUserImage"), color: localStorage.getItem("currentUserColor") }));
    }
  },
  watch: {
    '$route': function(val) {
      this.users = []
      this.activeUsers = []
      if (this.connection.readyState) {
        this.connection.send(JSON.stringify({ type: 'Redirect', path: val.path, image: localStorage.getItem("currentUserImage"), color: localStorage.getItem("currentUserColor") }));
      }
      this.route = val.path
      this.loading = true
    },
  }
}
</script>
