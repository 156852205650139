<template>
  <q-layout view="hHh lpr fFf">
    <Header :connection="wsConnection" :active-users="users" />
    <Footer />
    <q-page-container>
      <q-page class="prop-tile-page">
        <div class="row q-ma-lg q-my-sm items-center sort-list-toggle-container">
          <div class="col-12 col-sm-4 gt-xs">
            <h4 class="font-w300 text-secondary q-ma-sm welcome">Welcome {{ this.firstName }}</h4>
          </div>
          <q-space class="gt-xs" />

          <!-- Portfolio Filter Section -->
          <div class="sort-btn-container">

          <div class="q-pr-md" id="portfolio-sort-btn">
            <!-- Sort by -->
            <q-btn-dropdown flat color="primary" :label="'Sort by: ' + sort" v-if="enviornment === 'development'">
              <q-list>
                <!-- Dropdown Options -->
                <q-item clickable v-close-popup @click="sort='A - Z'">
                  <q-item-section>
                    <q-item-label>A - Z</q-item-label>
                  </q-item-section>
                </q-item>

                <q-item clickable v-close-popup @click="sort='Newest'">
                  <q-item-section>
                    <q-item-label>Newest</q-item-label>
                  </q-item-section>
                </q-item>

                <q-item clickable v-close-popup @click="sort='Last Edited'">
                  <q-item-section>
                    <q-item-label>Last Edited</q-item-label>
                  </q-item-section>
                </q-item>

                <q-item clickable v-close-popup @click="sort='Publish Status'">
                  <q-item-section>
                    <q-item-label>Publish Status</q-item-label>
                  </q-item-section>
                </q-item>
                
              </q-list>
            </q-btn-dropdown>
          </div>
          </div>

          <q-space class="lt-sm" />
          
          <!-- Grid and List View Toggle Buttons -->
          <div class="grid-list-btn-container">
            <q-btn-group id="grid-list-btn" class="portfolio-views q-mr-md no-shadow">
              <q-btn @click="toggleGrid()" :class=' "grid-" + view' dense outline color="primary" class="grid-list-btn" icon="grid_view" />
              <q-btn @click="toggleList()" :class=' "list-" + view' dense outline color="primary" class="grid-list-btn" icon="list" />
            </q-btn-group>
          </div>

        </div>
        <!-- page content -->
        <property-tile v-if="view == 'grid' && propertiesLoaded" :connection="wsConnection" :properties-list="sortedProperties"/>
        <property-list v-if="view == 'list' && propertiesLoaded" :connection="wsConnection" :properties-list="sortedProperties"/>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script>
import { LocalStorage, Notify } from 'quasar'
import Header from "../components/header/TheHeader.vue";
import Footer from "../components/footer/TheFooter.vue";
import PropertyTile from "../components/properties/PropertyTile.vue";
import PropertyList from "../components/properties/PropertyList.vue";
import axios from "axios";
import sympleApi from '@/api/symple-api'
import { slackNotifier } from '../components/helpers/helpers.js'

export default {
  name: "Properties",
  props: ['activeUsers', 'connection'],
  created() {
    this.getProperties();
  },
  data() {
    return {
      users: this.activeUsers,
      wsConnection: this.connection,
      firstName: localStorage.getItem("currentUserFirstName"),
      view: LocalStorage.getItem('view'),
      properties: [],
      propertiesLoaded: false,
      sort: 'A - Z',
      enviornment: process.env.NODE_ENV
    }
  },
  components: {
    Header,
    PropertyTile,
    Footer,
    PropertyList,
  },
  methods: {
    getProperties() {
      axios
        .get(`${sympleApi.sympleApiBaseURL()}properties`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        })
        .then((response) => {
          const properties = response.data.data;
          this.properties = properties.reverse();
          this.propertiesLoaded = true;
        })
        .catch((error) => {
          slackNotifier(error);
          if(error.response.status === 403) {
            this.logout();
          }
        });
    },
    toggleGrid() {
      this.view = 'grid'
      localStorage.setItem('view', 'grid'); 
    },
    toggleList() {
      this.view = 'list'
      localStorage.setItem('view', 'list'); 
    },
    logout() {
      if (process.browser) {
        this.wsConnection.send(JSON.stringify({ type: 'Remove Position', image: localStorage.getItem("currentUserImage") }));
        localStorage.removeItem("authToken");
        localStorage.removeItem("currentUserImage");
        localStorage.removeItem("currentUserColor");
      }
    
      this.$router.push({ name: 'Login', params: { logout: true }});
      // CSS Change
      if (document.body.classList.contains('body--dark')) { //when the body has the class 'dark' currently
          document.body.classList.add("body--light");
          document.body.classList.remove("body--dark");
        } 
    },
  },
  mounted() {
    // Login notification 
    if (this.$route.params.login) {
      Notify.create({
        type: 'positive',
        message: 'Logged In!',
        timeout: 4000,
        color: 'positive'
      })
    }

    // grid/list view localStoraage check/set
    if (localStorage.getItem('view') == null) {
      this.toggleGrid()
    }
  },
  watch: {
    activeUsers: function(val) {
      this.users = val
    },
    connection: function(val) {
      this.wsConnection = val
    }
  },
  computed: {
    sortedProperties() {
      let properties = this.properties;
      if (this.sort === 'A - Z') {
        properties = properties.sort((a, b) => a.attributes.name.localeCompare(b.attributes.name))
      } else if (this.sort === 'Newest') {
        properties = properties.sort((a, b) => b.attributes.created_at.localeCompare(a.attributes.created_at))
      } else if (this.sort === 'Last Edited') {
        properties = properties.sort((a, b) => b.attributes.updated_at.localeCompare(a.attributes.updated_at))
      } else if (this.sort === 'Publish Status') {
        properties = properties.sort((a, b) => b.attributes.status.localeCompare(a.attributes.status))
      } 
      return properties; 
    },
  }
};
</script>
