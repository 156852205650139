import { createStore } from 'vuex'
import axios from 'axios'
import sympleApi from '@/api/symple-api'
import createPersistedState from 'vuex-persistedstate'

export const store = createStore({
  plugins: [createPersistedState({
    subscriber(store) {
      return (handler) => store.subscribe(handler)
    }
  })],
  state() {
    return {
      admin: false,
      user: {},
      token: localStorage.getItem('authToken') || ''
    }
  },
  mutations: {
    auth_success(state, user){
      state.user = user
      if(user.attributes.is_admin === 1) {
        state.admin = true
      }
    },
    auth_error(state){
      state.user = {}
      state.token = ''
      localStorage.setItem("authToken", "")
    },
    logout(state){
      state.user = {};
      state.token = '';
    }
  },
  actions: {
    login({commit}, token){
      return new Promise((resolve, reject) => {
        axios
          .get(`${sympleApi.sympleApiBaseURL()}users/current`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then(resp => {
            const user = resp.data.data;
            commit('auth_success', user);
            resolve(resp);
          })
          .catch(err => {
            commit('auth_error');
            localStorage.removeItem('authToken');
            reject(err);
          });
      });
    },
    fetchUser({commit, state}){
      return axios
        .get(`${sympleApi.sympleApiBaseURL()}users/current`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        })
        .then((response) => {
          const userData = response.data.data;
          if (JSON.stringify(userData) !== JSON.stringify(state.user)) {
            commit('auth_success', userData);
          }
          return userData;
        })
        .catch(() => {
          commit('auth_error')
        })
    }
  },
  getters: {
    getToken: state => state.token,
    isLoggedIn: state => !!state.token,
    getUser: state => JSON.parse(JSON.stringify(state.user)),
    isAdmin: state => state.admin
  }
})