<template>
  <q-header flat class="symple-purple-gradient">
    <q-toolbar class="symple-header">
      <q-toolbar-title>
        <div class="row items-center">
          <!-- logo desktop / tablet -->
          <router-link to="/properties"
          ><img
              href="/properties"
              src="../../assets/images/logo-white.svg"
              class="q-pr-lg gt-sm col-1"
              height="38"
          /></router-link>
          <!-- logo mobile -->
          <router-link to="/properties"
          ><img
              href="/properties"
              src="../../assets/images/symple.svg"
              class="q-pr-md lt-md col"
              height="30"
          /></router-link>
          <search-bar></search-bar>
        </div>
      </q-toolbar-title>
      <users-menu v-if="portfolio !== null" :portfolio="portfolio" :active-users="users"></users-menu>
      <alerts-menu></alerts-menu>
      <!-- <q-btn
        class="q-px-xs gt-sm"
        flat
        rounded
        icon="apps"
        title="View Properties"
      />
      <q-btn
        class="q-px-xs gt-sm"
        flat
        rounded
        icon="add"
        title="New Property"
      /> -->
      <account-menu v-if="portfolio !== null && user !== null" :user="user" :portfolio="portfolio" :connection="wsConnection"></account-menu>
    </q-toolbar>
  </q-header>
</template>

<script>
import axios from 'axios';
import AccountMenu from "./AccountMenu.vue";
import AlertsMenu from "./AlertsMenu.vue";
import SearchBar from "./SearchBar.vue";
import UsersMenu from "./UsersMenu.vue";
import sympleApi from '@/api/symple-api'
import { slackNotifier } from '../helpers/helpers.js'

export default {
  props: ['activeUsers', 'connection'],
  components: {
    AccountMenu,
    AlertsMenu,
    SearchBar,
    UsersMenu
  },
  created() {
    this.getUser();
    this.getPortfolio();
  },
  data() {
    return {
      users: this.activeUsers,
      user: null,
      portfolio: null,
      userStatus: this.status,
      wsConnection: this.connection
    };
  },
  methods: {
    getUser() {
      axios
          .get(`${sympleApi.sympleApiBaseURL()}users/current/`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          })
          .then((response) => {

            this.user = response.data.data
          })
          .catch((error) => {
            slackNotifier(error);
          })
    },
    getPortfolio() {
      axios
          .get(
              `${sympleApi.sympleApiBaseURL()}portfolios/`,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                },
              }
          ).then((response) =>  {
        this.portfolio = response.data.data
      })
    }
  },
  watch: {
    activeUsers: function(val) {
      this.users = val
    },
    connection: function(val) {
      this.wsConnection = val
    }
  }
};
</script>,