<template>
  <q-form novalidate @submit="submitNew" class="q-gutter-md q-pt-md" name="addSpecialForm">
    <!-- Header -->
    <label class="input-label text-bold text-grey">Header<span class="text-negative"> *</span></label>
    <q-input
      no-error-icon
      dense
      outlined
      v-model="specialData.header"
      class="q-mt-xs"
      lazy-rules
      :rules="[(val) => val && val.length > 0]"
    />

    <!-- Description  -->
    <label class="input-label text-bold text-grey">Description</label>
    <q-input
      dense
      v-model="specialData.description"
      outlined
      type="textarea"
      style="border-radius: 5px;"
      class="q-mt-xs q-mb-md"
    />

    <!-- Date Ranges  -->
    <label class="input-label text-bold text-grey">Start Date</label>
    <q-input
      class="q-mt-xs q-mb-md"
      dense
      outlined
      v-model="specialData.start_date"
      type="date"
      range
      style="border-radius: 5px;"
    ></q-input>

    <label class="input-label text-bold text-grey">End Date</label>
    <q-input
      class="q-mt-xs q-mb-md"
      dense
      outlined
      v-model="specialData.end_date"
      type="date"
      range
      style="border-radius: 5px;"
      color="purple"
    ></q-input>

    <!-- Assign Properties  -->
    <label for="modelAddUnique" class="input-label text-bold text-grey"
      >Assign Properties</label
    >

    <q-select
      dense
      outlined
      use-chips
      use-input
      multiple
      map-options
      hide-dropdown-icon
      new-value-mode="add-unique"
      v-model="specialData.property_ids"
      input-debounce="0"
      :options="propertySelect"
      style="border-radius: 5px;"
      class="q-mt-sm q-mb-md"
      for="assign-property-input-add"
      v-on:keyup="assignPropertyFilterAdd"
      behavior="menu"
      v-on:update:modelValue="updateParams"
    />

    <!-- Assign Groups  -->
    <label for="modelAddUnique" class="input-label text-bold text-grey"
      >Assign Groups</label
    >

    <q-select
      dense
      outlined
      use-chips
      use-input
      multiple
      map-options
      hide-dropdown-icon
      new-value-mode="add-unique"
      v-model="specialData.groups"
      input-debounce="0"
      :options="groupSelect"
      style="border-radius: 5px;"
      class="q-mt-sm "
      for="assign-group-input-add"
      v-on:keyup="assignGroupFilterAdd"
      behavior="menu"
    />

    <!-- Submit Btn  -->
    <q-item-section class="q-pa-md border-bottom">
      <q-btn
        color="positive"
        text-color="white"
        unelevated
        label="Save"
        type="submit"
        class="q-mb-sm text-weight-bold"
        style="border-radius: 50px !important"
      />
    </q-item-section>
  </q-form>
</template>
<style>
.add-header-input {
  min-height: 40px;
  padding: 5px 14px 5px 14px !important;
}
.add-header-input-container {
  min-height: 40px;
  border-radius: 5px;
  border: 1px solid rgb(202, 206, 250);
}
.border-red {
  border: 1px solid #c81f25;
}
</style>
<script>
import axios from "axios";
import { date } from "quasar";
import { ref } from "vue";
import { Notify } from "quasar";
import sympleApi from '@/api/symple-api'
import { slackNotifier } from '../helpers/helpers.js'

export default {
  name: "AddSpecial",
  props: ["properties", "groups"],
  data() {
    // Add User Form
    return {
      propertySelect: this.properties,
      groupSelect: this.groups,
      specialData: {
        id: "",
        user_id: "",
        header: "",
        description: "",
        start_date: "",
        end_date: "",
        property_ids: ref([]),
        groups: ref([]),
        status: 0,
      },
    };
  },
  watch: {
    properties() {
      this.propertySelect = this.properties;
    },
    groups() {
      this.groupSelect = this.groups;
    }
  },
  methods: {
    addSpecial() {
      var today = new Date();
      var dd = today.getDate();
      var mm = today.getMonth()+1; 
      var yyyy = today.getFullYear();

      if(dd<10) 
      {
          dd='0'+dd;
      } 

      if(mm<10) 
      {
          mm='0'+mm;
      } 

      today = yyyy+'-'+mm+'-'+dd;

      var start_date = new Date(Date.parse(this.specialData.start_date))
      var end_date = new Date(Date.parse(this.specialData.end_date))
      start_date.setMinutes(start_date.getMinutes()+start_date.getTimezoneOffset())
      end_date.setMinutes(end_date.getMinutes()+end_date.getTimezoneOffset())
      end_date.setHours(23,59,59,999);
      this.specialData.start_date = date.formatDate(start_date, 'YYYY-MM-DD')
      this.specialData.end_date = date.formatDate(end_date,'YYYY-MM-DD')

      if (this.specialData.start_date === undefined) {
        this.specialData.start_date = today
      } 

      this.specialData.property_ids = this.specialData.property_ids.map(
        (property) => parseInt(property.value)
      );
      // OLD Header Validation
      // let x = document.forms["addSpecialForm"]["add-special-header"].value;
      // if (x == "") {
      //   var element = document.getElementById("add-special-header");
      //   element.classList.add("border-red");
      //   return false;
      // }
      axios
        .post(
          `${sympleApi.sympleApiBaseURL()}specials`,
          this.specialData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          }
        )
        .then(() => {
          Object.assign(this.$data, this.$options.data.call(this));
          this.$emit("updateSpecials");
          this.$emit("closeAdd");
          //Refresh special data
        })
        .catch((error) => {
          slackNotifier(error);
        });
    },
    updateParams(value) {
      this.$emit("updateGroupsParams", value)
    },
    submitNew() {
      try {
        this.addSpecial();
        Notify.create({
          type: "positive",
          message: "Saved!",
          timeout: 1000,
          color: "positive",
        });
      } catch (error) {
        slackNotifier(error);
        Notify.create({
          type: "negative",
          message: "An error occurred while saving!",
          timeout: 1000,
          color: "negative",
        });
      }
    },
    assignPropertyFilterAdd() {
      var td, i, txtValue;
      var input = document.getElementById("assign-property-input-add");
      var filter = input.value.toUpperCase();
      var table = document.getElementById("qvs_1");
      var tr = table.getElementsByClassName("q-item");

      // Loop through all table rows, and hide those who don't match the search query
      for (i = 0; i < tr.length; i++) {
        td = tr[i].getElementsByTagName("span")[0];

        if (td) {
          txtValue = td.textContent || td.innerText;
          if (
            txtValue.toUpperCase().indexOf(filter) > -1 
          ) {
            tr[i].style.display = "";
          } else {
            tr[i].style.display = "none";
          }
        }
      }
    },
    assignGroupFilterAdd() {
      var td, i, txtValue;
      var input = document.getElementById("assign-group-input-add");
      var filter = input.value.toUpperCase();
      var table = document.getElementById("qvs_2");
      var tr = table.getElementsByClassName("q-item");

      // Loop through all table rows, and hide those who don't match the search query
      for (i = 0; i < tr.length; i++) {
        td = tr[i].getElementsByTagName("span")[0];

        if (td) {
          txtValue = td.textContent || td.innerText;
          if (
            txtValue.toUpperCase().indexOf(filter) > -1 
          ) {
            tr[i].style.display = "";
          } else {
            tr[i].style.display = "none";
          }
        }
      }
    },
  },
};
</script>
