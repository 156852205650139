<template>
  <div>
    <!-- User Table  -->
    <div
      class="
        q-table__container 
        q-table--horizontal-separator
        column
        no-wrap
        q-table__card        
        q-table--no-wrap
        no-shadow
        q-py-md
      "
    >
      <!---->
      <div class="q-table__middle scroll">
        <table 
          class="q-table"
          id="users-table"
          >
          <thead>
            <tr class="titles">
              <th class="text-center gt-sm"><q-icon name="person"/></th>
              <th class="text-left">Name</th>
              <th class="text-left gt-sm">Company</th>
              <th class="text-left gt-xs">Email</th>
              <th class="text-left gt-md">Last Seen</th>
              <th class="text-left gt-md">Status</th>
              <th class="text-right">Edit/Delete</th>
            </tr>
          </thead>
          <tbody>
            <tr class="user-row" v-for="user in userData" :key="user.id">
              <td class="text-center gt-sm">
                <q-avatar>
                  <img :src="avatarUrl(user)" />
                </q-avatar>
              </td>
              <td class="text-left user-name">
                {{ user.attributes.first_name }}
                {{ user.attributes.last_name }}
                <q-badge v-if="user.attributes.is_admin" color="positive"
                  >Admin
                </q-badge>
              </td>
              <td class="text-left gt-sm user-company">{{ user.attributes.company }}</td>
              <td class="text-left gt-xs user-email">{{ user.attributes.email }}</td>
              <td class="text-left gt-md user-last-active">{{ formatDate(user.attributes.last_sign_in_at) }}</td>
              <td class="text-left gt-md user-status">{{ formatStatus(user.attributes.locked_at) }}</td>
              <td class="text-right">
              <q-btn
              class="q-mr-xs user-action text-dark edit-icon"
              padding="5px"
              icon="fas fa-edit"
              size="xs"
              @click="toggleRightDrawer3(user)"
            />
              <q-btn
              class="user-action text-dark edit-icon"
              style="padding-left: 5px !important"
              padding="5px"
              icon="fas fa-trash"
              size="xs"
              @click="deleteUserModal = true"
              />
              </td>
              <!-- Delete Modal  -->
              <q-dialog v-model="deleteUserModal" persistent>
                <q-card class="q-pa-lg">
                  <q-card-section class="q-mb-lg">
                    <div class="row justify-center q-mb-md">
                      <span
                        ><i class="fa fa-2x fa-exclamation-triangle text-negative"></i
                      ></span>
                    </div>
                    <div class="row items center">
                      <span class="q-ml-sm"
                        >Are you sure you want to delete this user?
                      </span>
                    </div>
                  </q-card-section>
                  <q-card-actions align="center">
                    <q-btn outline label="Yes" color="positive" @click="deleteUser(user)" v-close-popup />
                    <q-btn label="No" color="negative" v-close-popup />
                  </q-card-actions>
                </q-card>
              </q-dialog>
              <!-- end delete modal  -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- End user Table  -->

    <!-- EDIT USER FORM START -->
    <q-drawer v-model="rightDrawerOpen3" side="right" overlay bordered>
      <q-scroll-area class="fit">
      <q-item-section class="q-pt-md q-pl-md">
        <q-toolbar class="q-pl-none">
          <span class="text-h6 text-left q-pb-xs">Edit User</span>
          <q-space />
          <q-icon
            @click="toggleRightDrawer3"
            class="q-pb-xl q-pl-lg"
            name="fas fa-times"
            style="color: #c1c1c1"
            size="20px"
          ></q-icon>
        </q-toolbar>
      </q-item-section>
      <q-item-section class="q-pa-md">
        <edit-user :user="userEdit" @updateUsers="updateUsers" @closeEdit="toggleRightDrawer3"> </edit-user>
      </q-item-section>
      </q-scroll-area>
    </q-drawer>
  </div>
</template>
 
 <style lang="scss" scoped>
.user-action {
  border: 1px solid black;
  border-color: #cacfea;  
}
.titles th {
  font-size: 14px!important;
  font-weight: 600;
}
.titles .q-icon {
  font-size: 20px;
}
</style>

<script>
window.$ = window.jQuery = window.jquery = require("jquery");
import axios from "axios";
import { ref } from "vue";
import EditUser from './EditUser.vue';
import defaultAvatar from '../../assets/images/avatar-default.png'
import sympleApi from '@/api/symple-api'
import { slackNotifier } from '../helpers/helpers.js'

// Table Columns
// var deleteBtn = "<q-btn size='sm' color='accent' round dense></q-btn>"

export default {
  components: { EditUser },
  name: "UserData",
  props: ['users'],
  setup(props) {
    const rightDrawerOpen3= ref(false)
    return {
      props,
      rightDrawerOpen3,
      deleteUserModal: ref(false),
    };
  },
  data() {
    return {
      userEdit: '',
      userData: this.users
    };
  },
  watch:{    
    users(){
      this.userData = this.users;
    }    
  },
  methods: {
    formatDate(lastSignIn) {
      const now = new Date();
      const lastSignInDate = new Date(lastSignIn);
      const diffTime = Math.abs(now - lastSignInDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 25));
      return diffDays + " days ago"
    },
    formatStatus(lockedAt) {
      return lockedAt ? "Locked" : "Active";
    },
    deleteUser(user) {
      axios
        .delete(`${sympleApi.sympleApiBaseURL()}users/` + user.id, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        })
        .then(() => {
          this.$emit('updateUsers');
          //Refresh Users data
        })
        .catch((error) => {
          slackNotifier(error);
        })
    },
    toggleRightDrawer3(selected) {
      this.deleteUserModal = false
      if(!this.rightDrawerOpen3) {
        this.userEdit=selected
      }
      this.rightDrawerOpen3 = !this.rightDrawerOpen3;
    },
    updateUsers() {
      this.$emit("updateUsers");
    },
    avatarUrl(user) {
      if(user.attributes.avatar_url.url)
        return user.attributes.avatar_url.url
      else
        return defaultAvatar
    }
  }
};
</script>

<style lang="scss">
// User table borders
#users-table.q-table {
  border-top: 1px solid #cad0ea !important;
  .q-table tr.users-row {
    border-top-width: 1px !important;
  }
}
</style>