<template>
  <q-page-container>
    <q-drawer v-model="rightDrawerOpen" side="right" overlay 
     class="edit-left-drawer"
      style="z-index: 100 !important;">
      <q-scroll-area class="fit">
      <q-item-section class="q-pt-md q-pl-md">
        <q-toolbar class="q-pl-none">
          <span class="text-h6 text-left q-pb-xs">Add User</span>
          <q-space />
          <q-icon
            @click="toggleRightDrawer"
            class="q-pb-xl q-pl-lg"
            name="fas fa-times"
            style="color: #c1c1c1"
            size="20px"
          ></q-icon>
        </q-toolbar>
      </q-item-section>

      <q-item-section class="q-pa-md">
        <add-user @updateUsers="getUsers"></add-user>
      </q-item-section>
      </q-scroll-area>
    </q-drawer>
    <q-page 
      class="content q-px-md"
      id='users-table'
      >
      <q-toolbar class="filter-add-icon">
        <input-filter></input-filter>
        <q-space />
        <q-btn
          rounded
          @click="toggleRightDrawer"
          color="primary"
          class="text-bold add-user-btn q-px-lg"
        >
          <i class="fas fa-plus q-mr-sm"></i>
            User
        </q-btn>
      </q-toolbar>
      <user-data :users="userData" @updateUsers="getUsers"></user-data>
    </q-page>
    <router-view />
  </q-page-container>
</template>

<style lang="scss" scoped>
.rounded-corners {
  border-radius: 16px;
}
.search-bar {
  border: 1px solid #cacfea;
  border-radius: 25px;
}
</style>

<script>
import InputFilter from "../inputs/InputFilter.vue";
import UserData from "./UserData.vue";
import AddUser from "./AddUser.vue";
// import { Loading } from 'quasar'
import axios from "axios";
import { ref } from "vue";
import sympleApi from '@/api/symple-api'
import { slackNotifier } from '../helpers/helpers.js'

export default {
  components: { UserData, AddUser, InputFilter },
  setup() {
    const rightDrawerOpen = ref(false);
    return {
      rightDrawerOpen,
      toggleRightDrawer() {
        rightDrawerOpen.value = !rightDrawerOpen.value;
      },
    };
  },
  created() {
    this.getUsers();
  },
  data() {
    return {
      userData: [],
    };
  },
  methods: {
    getUsers() {
      // Loading.show();
      axios
        .get(`${sympleApi.sympleApiBaseURL()}users/`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }) 
        .then((response) => {
          this.userData = response.data.data;
          // Loading.hide();
        })
        .catch((error) => {
          slackNotifier(error);
        });

    },
  },
};
</script>
