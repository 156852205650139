<template>
  <q-layout view="hHh LpR fFf" v-if="propertiesLoaded">
    <the-header
      :connection="wsConnection"
      :active-users="menuUsers"
    ></the-header>

    <q-drawer
      show-if-above
      v-model="leftDrawerOpen"
      side="left"
      class="edit-right-drawer"
    >
      <q-scroll-area class="fit">
        <!-- LEFT SIDEBAR -->
        <q-item-section class="q-pa-md q-mb-xs">
          <span class="text-h6 text-left q-pb-xs"
            >{{ property.name }}
            <q-btn
              title="Visit Site"
              flat
              round
              dense
              class="q-ma-none q-pa-none"
              ><a
                :href="property.siteUrl"
                target="_blank"
                class="q-ma-none q-pa-none"
                ><i
                  class="q-ma-none q-pa-none fas fa-external-link-alt fa-xl"
                ></i></a></q-btn
          ></span>

          <span class="text-caption text-grey">
            <i
              v-if="
                property.status === 'Recently Published' ||
                property.status === 'Published'
              "
              class="fa fa-check-circle text-positive"
            ></i>
            <i
              v-if="property.status === 'Not Published'"
              class="fa fa-exclamation-circle text-warning"
            ></i>
            <q-spinner
              v-if="
                ['Building', 'Processing', 'Saving'].includes(property.status)
              "
              color="warning"
              size="1em"
              class="q-mb-xs"
            />
            <i
              v-if="
                ['Recently Failed', 'Failed', 'Publish Error', null].includes(
                  property.status
                )
              "
              class="fa fa-exclamation-circle text-negative"
            ></i>
            <span
              v-if="property.status === 'Recently Failed'"
              class="q-ml-xs q-mb-xs"
              >Oops, something went wrong.</span
            >
            <span
              v-else-if="property.status === 'Recently Published'"
              class="q-ml-xs q-mb-xs"
              >All content has been published!</span
            >
            <span v-else class="q-ml-xs q-mb-xs">{{
              property.status === "Recently Published"
                ? "Published!"
                : property.status === "Recently Failed"
                ? "Publish Failed!"
                : property.status
            }}</span>
          </span>
        </q-item-section>
        <q-expansion-item
          group="pages"
          label="Pages"
          default-opened
          header-class="
            bg-accent2
            q-mx-md 
            text-dark 
            text-bold 
            rounded-borders"
        >
          <q-list 
            dense
            class="">
            <Groups
              :groups="groupsList"
              @activeGroup="setActiveGroup"
              @removeGroup="removeGroup"
            />
          </q-list>
        </q-expansion-item>

        <q-expansion-item
          v-if="environment === 'development'"
          group="shared"
          label="Shared"
          default-closed
          header-class="
            bg-accent2
            q-mx-md 
            text-dark 
            text-bold 
            rounded-borders
            q-mt-sm
            "
        >
          <q-list dense class="">
            <q-item-section
              class="list-group-item justify-center"
            >
              <q-item
                dense
                class="
                  q-my-none q-pa-none q-px-lg q-mx-md
                  text-subtitle2
                  items-center
                  group-hover
                "
              >
                <div class="group-input">Header</div>
                <q-space />
              </q-item>
              <q-item
                dense
                class="
                  q-my-none q-pa-none q-px-lg q-mx-md
                  text-subtitle2
                  items-center
                  group-hover
                "
              >
                <div class="group-input">Footer</div>
                <q-space />
              </q-item>
            </q-item-section>
          </q-list>
        </q-expansion-item>

        <q-expansion-item
          v-if="environment === 'development'"
          group="tools"
          label="Tools"
          default-closed
          header-class="
            bg-accent2
            q-mx-md 
            text-dark 
            text-bold 
            rounded-borders
            q-mt-sm
            "
        >
          <q-list dense class="">
            <q-item-section
              @click="modal = true"
              class="list-group-item justify-center"
            >
              <q-item
                dense
                class="
                  q-my-none q-pa-none q-px-lg q-mx-md
                  text-subtitle2
                  items-center
                  group-hover
                "
              >
                <div class="group-input">Availability</div>
                <q-space />
              </q-item>
            </q-item-section>
          </q-list>
        </q-expansion-item>

        <!-- Tools Modal TEMPORARY -->

        <q-dialog v-model="modal">
          <q-card>
            <!-- <AvailibilityAugmentation /> -->
            <q-table :title="property.name" />
          </q-card>
        </q-dialog>

        <!-- New Group/Field Button -->
        <q-btn
          flat
          round
          color="primary"
          title="New Group"
          width="40px"
          class="q-px-xs q-ml-md q-mt-xs q-mb-sm sidebar-add-btn"
          @click="addGroup"
        >
          <q-img
            class="add-group-btn"
            src="@/assets/images/icons/icon-plus.svg"
            style="color: #cacfea; width: 30px; height: 30px"
          />
          <!-- <q-menu>
            <q-item clickable v-close-popup @click="addGroup">
              <q-item-section class="justify-center">
                <q-item-label
                  >Group
                  <i class="fas fa-list text-muted float-right q-ml-sm"></i
                ></q-item-label>
              </q-item-section>
            </q-item>
          </q-menu> -->
        </q-btn>
      </q-scroll-area>
    </q-drawer>

    <!-- RIGHT SIDEBAR -->
    <q-drawer
      show-if-above
      v-model="rightDrawerOpen"
      side="right"
      class="edit-left-drawer"
    >
      <q-scroll-area class="fit">
        <q-item-section class="q-pa-md border-bottom" style="border-bottom-width: 2px">
          <q-btn
            color="positive"
            text-color="white"
            unelevated
            class="q-mb-sm text-weight-bold"
            style="border-radius: 50px !important"
            @click="
              formSave();
              simulateProgress(1);
            "
            :loading="loading[1]"
          >
            Save
            <template v-slot:loading>
              <q-spinner class="on-left"></q-spinner>
              Saving...
            </template>
          </q-btn>
          <q-btn
            v-if="
              [
                'Not Published',
                'Published',
                'Failed',
                'Publish Error',
              ].includes(property.status)
            "
            outline
            style="color: #13b990; border-radius: 50px !important"
            class="text-weight-bold"
            :disabled="isDisabled"
            @click="formPublish()"
          >
            Publish
          </q-btn>
          <q-btn
            v-if="property.status === 'Recently Published'"
            outline
            disable
            class="text-weight-bold published-btn"
          >
            Published!
            <q-icon
              name="fas fa-check-circle"
              size="1em"
              color="#cad0ea"
              class="q-pl-xs"
            />
          </q-btn>
          <q-btn
            v-if="property.status === 'Recently Failed'"
            outline
            disable
            class="text-weight-bold failed-btn"
          >
            Publish Failed
            <q-icon
              name="fa fa-exclamation-circle"
              size="1em"
              color="#cad0ea"
              class="q-pl-xs"
            />
          </q-btn>
          <q-btn
            v-if="
              ['Building', 'Processing', 'Saving'].includes(property.status)
            "
            outline
            disabled
            class="text-weight-bold building-btn"
          >
            <q-spinner class="on-left"></q-spinner>
            {{ property.status }}...
          </q-btn>
        </q-item-section>
        <q-item-section>
          <q-list>
            <q-expansion-item
              group="settings"
              label="Settings"
              default-opened
              header-class="text-dark"
            >
              <div class="q-pa-md">
                <label for="name" class="input-label text-bold">Name</label>
                <q-input
                  outlined
                  dense
                  v-model="property.name"
                  class="q-px-md q-mt-sm q-mb-lg input-space"
                  style="border-radius: 5px"
                />

                <label for="name" class="input-label text-bold">Location</label>
                <q-input
                  outlined
                  dense
                  v-model="property.city"
                  class="q-px-md q-mt-sm q-mb-lg input-space"
                  style="border-radius: 5px"
                />

                <label for="filesImages" class="input-label text-bold"
                  >Property Image</label
                >
                <q-input
                  @update:model-value="
                    (val) => {
                      property.logoUrl = val[0];
                    }
                  "
                  dense
                  borderless
                  v-model="property.logoUrl"
                  type="file"
                  class="q-mt-sm q-mb-xs text-muted input-space"
                  style="margin-left: -13px"
                />
                <!-- <DropZone class="q-mb-md" style="width: 100%;"/> -->
                <q-img
                  class="q-mb-lg"
                  :src="propertyLogo"
                  style="border-radius: 5px"
                >
                </q-img>

                <label for="siteUrl" class="input-label text-bold"
                  >URL<a :href="property.siteUrl" target="_blank"
                    ><i
                      class="fa fa-external-link-alt text-muted q-pl-xs"
                    ></i></a
                ></label>
                <q-input
                  outlined
                  dense
                  v-model="property.siteUrl"
                  class="q-px-md q-mt-sm q-mb-lg input-space"
                  style="border-radius: 5px"
                />
              </div>
            </q-expansion-item>

            <q-separator v-if="isAdmin" />

            <q-expansion-item
              group="admin"
              label="Admin"
              v-if="isAdmin"
              header-class="text-dark"
            >
              <div class="q-pa-md">
                <label for="users" class="input-label text-bold">Users</label>
                <q-select
                  dense
                  outlined
                  :options="userOptions"
                  v-model="property.users"
                  use-input
                  use-chips
                  multiple
                  emit-value
                  map-options
                  hide-dropdown-icon
                  input-debounce="0"
                  new-value-mode="add-unique"
                  style="border-radius: 5px"
                  class="q-px-md q-mt-sm q-mb-lg input-space"
                ></q-select>

                <label for="webhook" class="input-label text-bold"
                  >Webhook</label
                >
                <q-input
                  outlined
                  dense
                  v-model="property.netlifyWebhook"
                  class="q-px-md q-mt-sm q-mb-md input-space"
                  style="border-radius: 5px"
                />

                <div class="q-pa-md">
                  <q-btn
                    color="negative"
                    text-color="white"
                    unelevated
                    label="Delete"
                    class="text-weight-bold full-width items-center"
                    @click="deletePropertyModal = true"
                  />
                </div>
              </div>
            </q-expansion-item>

            <q-separator />
          </q-list>
        </q-item-section>
      </q-scroll-area>
    </q-drawer>

    <!-- MAIN CONTENT -->
    <q-page-container class="q-mt-sm edit-cards">
      <!-- Mobile Only Section -->
      <q-item-section class="q-pa-md q-mb-md lt-md">
        <q-toolbar class="text-primary">
          <q-toolbar-title>
            <span class="text-dark text-h6 text-left q-pb-xs">{{
              property.name
            }}</span>
            <br />
            <span class="text-caption text-grey">
              <i
                v-if="property.status === 'Recently Published'"
                class="fa fa-check-circle text-positive"
              ></i>
              <i
                v-if="property.status === 'Published'"
                class="fa fa-check-circle text-positive"
              ></i>
              <i
                v-if="property.status === 'Not Published'"
                class="fa fa-exclamation-circle text-warning"
              ></i>
              <i
                v-if="
                  ['Building', 'Processing', 'Saving'].includes(property.status)
                "
                class="fa fa-spinner fa-spin text-warning"
              ></i>
              <i
                v-if="
                  ['Recently Failed', 'Failed', 'Publish Error', null].includes(
                    property.status
                  )
                "
                class="fa fa-exclamation-circle text-negative"
              ></i>
              {{
                property.status === "Recently Published"
                  ? "Published!"
                  : property.status === "Recently Failed"
                  ? "Publish Failed!"
                  : property.status
              }}
            </span>
          </q-toolbar-title>
          <q-btn dense color="transparent" class="edit-mobile-menu-btn">
            <q-icon flat name="menu" color="secondary" />
            <q-menu
              flat
              round
              dense
              icon="menu"
              color="secondary"
              class="mobile-group-menu"
            >
              <q-list dense>
                <GroupMenu
                  :groups="groupsList"
                  @activeGroup="setActiveGroup"
                  @removeGroup="removeGroup"
                />
              </q-list>
            </q-menu>
          </q-btn>
        </q-toolbar>

        <div class="row justify-center items-center q-mt-md">
          <div class="col-6 q-px-sm">
            <q-btn
              color="positive"
              text-color="white"
              unelevated
              class="text-weight-bold full-width"
              style="border-radius: 50px !important"
              label="Save"
              @click="
                formSave();
                simulateProgress(1);
              "
              :loading="loading[1]"
            />
            <br />
          </div>
          <div class="col-6 q-px-sm">
            <q-btn
              v-if="
                [
                  'Not Published',
                  'Published',
                  'Failed',
                  'Publish Error',
                ].includes(property.status)
              "
              outline
              style="color: #13b990; border-radius: 50px !important"
              class="text-weight-bold full-width"
              @click="formPublish()"
            >
              Publish
            </q-btn>
            <q-btn
              v-if="property.status === 'Recently Published'"
              outline
              style="color: #13b990; border-radius: 50px !important"
              class="text-weight-bold full-width"
            >
              Published!
            </q-btn>
            <q-btn
              v-if="property.status === 'Recently Failed'"
              outline
              style="color: #13b990; border-radius: 50px !important"
              class="text-weight-bold full-width"
            >
              Publish Failed!
            </q-btn>
            <q-btn
              v-if="
                ['Building', 'Processing', 'Saving'].includes(property.status)
              "
              outline
              disable
              class="text-weight-bold full-width building-btn"
            >
              {{ property.status }}
            </q-btn>
          </div>
        </div>
      </q-item-section>
      <!-- End Mobile Only -->

      <div class="field-filters">
        <q-toolbar class="filter-block q-my-md q-mx-sm">
          <q-tabs
            v-model="tab"
            shrink
            no-caps
            dense
            indicator-color="transparent"
            mobile-arrows
          >
            <q-tab
              :ripple="false"
              name="tab1"
              label="All"
              @click="
                activeTab = 'All';
                onTabClick();
              "
            />
            <q-tab
              :ripple="false"
              name="tab2"
              label="Content"
              @click="
                activeTab = 'Content';
                onTabClick();
              "
            />
            <q-tab
              :ripple="false"
              name="tab3"
              label="SEO"
              @click="
                activeTab = 'SEO';
                onTabClick();
              "
            />
            <q-tab
              :ripple="false"
              name="tab4"
              label="Images"
              @click="
                activeTab = 'Images';
                onTabClick();
              "
            />
          </q-tabs>
          <q-space />
          <q-btn
            title="New Field"
            class="q-pa-xs q-mr-lg"
            style="border-radius: 30px"
          >
            <q-img
              class="add-group-btn"
              src="@/assets/images/icons/icon-plus.svg"
              style="color: #cacfea; width: 30px; height: 30px"
            />
            <q-menu class="add-field-menu">
              <q-item
                clickable
                v-close-popup
                @click="addField('TextField')"
                class="new-field-item"
              >
                <q-item-section class="justify-center">
                  <q-item-label
                    >Text Line
                    <i
                      class="fas fa-sticky-note text-muted float-right q-ml-sm"
                    ></i
                  ></q-item-label>
                </q-item-section>
              </q-item>
              <q-item
                clickable
                v-close-popup
                @click="addField('TextAreaField')"
              >
                <q-item-section class="justify-center">
                  <q-item-label
                    >Text Area
                    <i
                      class="far fa-file-alt text-muted float-right q-ml-sm"
                    ></i
                  ></q-item-label>
                </q-item-section>
              </q-item>
              <q-item
                clickable
                v-close-popup
                @click="addField('RichTextField')"
              >
                <q-item-section class="justify-center">
                  <q-item-label
                    >Rich Text
                    <i
                      class="fas fa-book-open text-muted float-right q-ml-sm"
                    ></i
                  ></q-item-label>
                </q-item-section>
              </q-item>
              <q-item clickable v-close-popup @click="addField('ImageField')">
                <q-item-section class="justify-center">
                  <q-item-label
                    >Image Field
                    <i class="far fa-image text-muted float-right q-ml-sm"></i
                  ></q-item-label>
                </q-item-section>
              </q-item>
              <q-item clickable v-close-popup @click="addField('InputField')">
                <q-item-section class="justify-center">
                  <q-item-label
                    >Input Field
                    <i
                      class="far fa-check-square text-muted float-right q-ml-sm"
                    ></i
                  ></q-item-label>
                </q-item-section>
              </q-item>
            </q-menu>
          </q-btn>
        </q-toolbar>
      </div>

      <!-- Showing All Fields by default -->
      <div>
        <draggable
          class="list-group"
          tag="div"
          :component-data="{
            tag: 'div',
            type: 'transition-group',
            name: !drag ? 'flip-list' : null,
          }"
          v-model="filteredFields"
          item-key="id"
        >
          <template #item="{ element, index }">
            <component
              :is="fieldComponent(element.input_type)"
              v-model="filteredFields[index]"
              @set-focused-field="setFocusedField(filteredFields[index])"
              @clear-focused-field="clearFocusedField(filteredFields[index])"
              @changed="changeAnnounce(filteredFields[index])"
              :remove-field="removeField"
              :group="
                groupsList.filter(
                  (group) => group.id == filteredFields[index].group
                )[0].name
              "
              v-bind:style="{ display: [element.active ? 'block' : 'none'] }"
              :data-active="element.active"
            ></component>
          </template>
        </draggable>
      </div>

      <!-- Add Field Button + Menu  -->
      <div class="row justify-center q-mb-lg">
        <q-btn title="New Field">
          <q-img
            class="add-group-btn"
            src="@/assets/images/icons/icon-plus.svg"
            style="color: #cacfea; width: 30px; height: 30px"
          />
          <q-menu>
            <q-item clickable v-close-popup @click="addField('TextField')">
              <q-item-section class="justify-center">
                <q-item-label
                  >Text Line
                  <i
                    class="fas fa-sticky-note text-muted float-right q-ml-sm"
                  ></i
                ></q-item-label>
              </q-item-section>
            </q-item>
            <q-item clickable v-close-popup @click="addField('TextAreaField')">
              <q-item-section class="justify-center">
                <q-item-label
                  >Text Area
                  <i class="far fa-file-alt text-muted float-right q-ml-sm"></i
                ></q-item-label>
              </q-item-section>
            </q-item>
            <q-item clickable v-close-popup @click="addField('RichTextField')">
              <q-item-section class="justify-center">
                <q-item-label
                  >Rich Text
                  <i class="fas fa-book-open text-muted float-right q-ml-sm"></i
                ></q-item-label>
              </q-item-section>
            </q-item>
            <q-item clickable v-close-popup @click="addField('ImageField')">
              <q-item-section class="justify-center">
                <q-item-label
                  >Image Field
                  <i class="far fa-image text-muted float-right q-ml-sm"></i
                ></q-item-label>
              </q-item-section>
            </q-item>
            <q-item clickable v-close-popup @click="addField('InputField')">
              <q-item-section class="justify-center">
                <q-item-label
                  >Input Field
                  <i
                    class="far fa-check-square text-muted float-right q-ml-sm"
                  ></i
                ></q-item-label>
              </q-item-section>
            </q-item>
          </q-menu>
        </q-btn>
      </div>
      <!-- Delete Modal  -->
      <q-dialog v-model="deletePropertyModal">
        <q-card class="q-pa-lg">
          <q-card-section class="q-mb-lg">
            <div class="row justify-center q-mb-md">
              <span
                ><i class="fa fa-2x fa-exclamation-circle text-negative"></i
              ></span>
            </div>
            <div class="row items center">
              <span class="q-ml-sm"
                >Are you sure you want to delete {{ property.name }}?
              </span>
            </div>
          </q-card-section>
          <q-card-actions align="center">
            <q-btn
              outline
              label="Yes"
              color="positive"
              @click="confirmDelete = true"
            />
            <q-btn label="No" color="negative" v-close-popup />
          </q-card-actions>
        </q-card>
      </q-dialog>
      <!-- End Delete Modal  -->
      <!-- CONFIRM Delete Modal  -->
      <q-dialog v-model="confirmDelete">
        <q-card class="q-pa-lg">
          <q-card-section class="q-mb-lg">
            <div class="row justify-center q-mb-md">
              <span
                ><i class="fa fa-exclamation-circle text-negative"></i
              ></span>
            </div>
            <div class="row items center">
              <span class="q-ml-sm"
                >To delete this property, type in its name and click Confirm.
              </span>
            </div>
            <q-input
              outlined
              dense
              class="q-px-md q-mt-md q-mb-md input-space"
              style="border-radius: 5px"
            />
          </q-card-section>
          <q-card-actions align="center">
            <q-btn
              outline
              label="Confirm"
              color="positive"
              @click="removeProperty()"
            />
            <q-btn label="Cancel" color="negative" v-close-popup />
          </q-card-actions>
        </q-card>
      </q-dialog>
      <!-- End CONFIRM Delete Modal  -->
      <!-- Start Availibility Tool Temp Modal -->
      <q-dialog full-width full-height>
        <q-card>
          <q-toolbar class="filter-block q-my-md q-mx-sm">
            <q-tabs
              shrink
              no-caps
              dense
              indicator-color="transparent"
              mobile-arrows
            >
              <q-tab name="tab1" label="Studio" />
              <q-tab name="tab2" label="1 Bed" />
              <q-tab name="tab3" label="2 Bed" />
              <q-tab name="tab4" label="3 Bed" />
            </q-tabs>
          </q-toolbar>
          <div class="q-my-md q-mx-lg">
          <div
            class="
              q-table__container 
              q-table--horizontal-separator
              column
              no-wrap
              q-table__card 
              q-table--no-wrap
              q-table--flat
              availibility-table
            "
          >
            <div class="q-table__middle scroll">
              <table class="q-table">
                <thead>
                  <tr>
                    <th class="text-left">Apartment</th>
                     <th class="text-left">Floor Plan</th>
                    <th class="text-left">Rent</th>
                    <th class="text-left">Bed/Bath</th>                  
                    <th class="text-left">Sq Ft</th>
                    <th class="text-left">Availability</th>
                    <th class="text-left">Icons</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="">
                    <td class="text-left">Murdoc</td>
                    <td class="text-left">A1</td>
                    <td class="text-left">1200</td>
                    <td class="text-left">1/1</td>
                    <td class="text-left">700</td>
                    <td class="text-left">Availabile</td>
                    <td class="text-left">0%</td>
                  </tr>
                  <tr class="">
                    <td class="text-left">Johnson</td>
                    <td class="text-left">A2</td>
                    <td class="text-left">2600</td>
                    <td class="text-left">2/1</td>
                    <td class="text-left">800</td>
                    <td class="text-left">Availabile</td>
                    <td class="text-left">0%</td>
                  </tr>
                  <tr class="">
                    <td class="text-left">Jackson</td>
                    <td class="text-left">B1</td>
                    <td class="text-left">1200</td>
                    <td class="text-left">3/1</td>
                    <td class="text-left">500</td>
                    <td class="text-left">Availabile</td>
                    <td class="text-left">0%</td>
                  </tr>
                  <tr class="">
                    <td class="text-left">Harlot</td>
                    <td class="text-left">B2</td>
                    <td class="text-left">1900</td>
                    <td class="text-left">3/1</td>
                    <td class="text-left">550</td>
                    <td class="text-left">Availabile</td>
                    <td class="text-left">0%</td>
                  </tr>
                  <tr class="">
                    <td class="text-left">Riverview</td>
                    <td class="text-left">B3</td>
                    <td class="text-left">3200</td>
                    <td class="text-left">700</td>
                    <td class="text-left">6.5</td>
                    <td class="text-left">Not Availabile</td>
                    <td class="text-left">0%</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          </div>
        </q-card>
      </q-dialog>
      <!-- Start Availibility Tool Temp Modal -->
    </q-page-container>
    <the-footer></the-footer>
  </q-layout>
</template>

<style lang="scss" scoped>
.fa-grip-lines,
.short-name {
  color: #cacfea;
}
.border-bottom {
  border-bottom: 1px solid #cacfea;
}
.q-input {
  padding: 5px 14px !important;
}
.q-item__section--main + .q-item__section--main {
  margin-left: 0px;
}
.q-field--outlined .q-field__control:before {
  border-radius: 5px !important;
  border-color: #cacfea;
}
.input-label {
  color: #495057;
}
.text-left {
  text-align: left !important;
}
.input-space {
  padding: 0px !important;
}
.sidebar-add-btn:hover {
  transition: all 0.7s cubic-bezier(0.165, 0.84, 0.44, 1);
  border-color: blue;
}
.img-success {
  float: right;
  margin-right: 5px;
  margin-top: 3px;
}
.failed-btn {
  color: #fff;
  border-radius: 50px !important;
  background-color: var(--q-negative) !important;
  border-color: var(--q-negative) !important;
}
.published-btn {
  color: #fff;
  border-radius: 50px !important;
  background-color: var(--q-positive) !important;
  border-color: var(--q-positive) !important;
}
.building-btn {
  color: #fff !important;
  border-color: #f9b600 !important;
  border-radius: 50px !important;
  background-color: #f9b600 !important;
}
</style>
<style lang="scss" global>
.card-hover {
  .field-input {
    font-size: 1rem;
    margin: 10px 0px !important;
    // padding: 10px 0px !important;
    font-weight: bold !important;
    text-align: left;

    &:hover {
      border-bottom: 1px solid #cacfea;
    }

    &:focus {
      border-bottom: 1px solid #00a3ff;
    }
  }

  .field-input-sm {
    color: #9e9e9e !important;
    font-size: 14px;
    font-weight: 100 !important;
  }
  .q-icon .q-btn-dropdown__arrow .q-btn-dropdown__arrow-container {
    display: none !important;
  }
}
</style>
<script>
import $ from "jquery";
import { ref, computed } from "vue";
import { useStore } from "vuex";
import axios from "axios";
import { Notify } from "quasar";
import { slackNotifier } from "../components/helpers/helpers.js";
import TheHeader from "../components/header/TheHeader.vue";
import TheFooter from "../components/footer/TheFooter.vue";
import TextArea from "../components/fields/TextArea.vue";
import TextLine from "../components/fields/TextLine.vue";
import InputField from "../components/fields/InputField.vue";
import RichText from "../components/fields/RichText.vue";
import ImageField from "../components/fields/ImageField.vue";
import Groups from "../components/groups/Groups.vue";
import GroupMenu from "../components/groups/GroupMenu.vue";
// import DropZone from '../components/fields/DropZone.vue';
import { debounce } from "lodash";
import sympleApi from "@/api/symple-api";
import draggable from "vuedraggable";

export default {
  props: ["activeUsers", "connection", "wsHandler"],
  data() {
    return {
      uid: "",
      property: {
        name: "Default Name",
        status: "Default Status",
        siteUrl: "Default Site URL",
        netlifyWebhook: "Default Webhook",
        city: "Default City",
        id: "Default ID",
        logoUrl: "Default Logo URL",
        users: [],
      },
      activeGroup: [],
      drag: false,
      groups: {},
      fields: [],
      users: [],
      userOptions: [],
      isLoaded: false,
      propertiesLoaded: false,
      wsConnection: this.connection,
      handler: this.wsHandler,
      menuUsers: this.activeUsers,
      activeTab: "All",
      focusedField: {},
      modal: false,
      environment: process.env.NODE_ENV,
    };
  },
  components: {
    TheHeader,
    TheFooter,
    TextLine,
    TextArea,
    RichText,
    ImageField,
    InputField,
    GroupMenu,
    Groups,
    draggable,
    // DropZone,
  },
  emits: ["handleWebhook"],
  mounted() {
    this.getPropertyData();
  },
  created() {
    this.uid = `Edit-${this.$uid}`;
    let self = this;

    this.wsConnection.onmessage = function (event) {
      let message = JSON.parse(event.data);
      let value = [];
      if (message.type === "Publish Status") {
        value = self.property;
      } else {
        value = self.fields;
      }
      self.$emit("handleWebhook", {
        message: JSON.parse(event.data),
        value: value,
      });
    };
  },
  setup() {
    const leftDrawerOpen = ref(false);
    const rightDrawerOpen = ref(false);
    const loading = ref([false, false, false, false, false, false]);
    const progress = ref(false);
    function simulateProgress(number) {
      // we set loading state
      loading.value[number] = true;
      // simulate a delay
      setTimeout(() => {
        // we're done, we reset loading state
        loading.value[number] = false;
      }, 3000);
    }
    const store = useStore();
    let isAdmin = computed(function () {
      return store.state.admin;
    });
    return {
      loading,
      progress,
      isAdmin,
      store,
      simulateProgress,
      leftDrawerOpen,
      toggleLeftDrawer() {
        leftDrawerOpen.value = !leftDrawerOpen.value;
      },

      rightDrawerOpen,
      toggleRightDrawer() {
        rightDrawerOpen.value = !rightDrawerOpen.value;
      },
      deletePropertyModal: ref(false),
      confirmDelete: ref(false),
      tab: ref("tab1"),
    };
  },
  updated() {
    this.$nextTick(function () {
      $(".search-options").hide();
      // $(`.list-group-item`).removeClass("active");
      this.wsConnection.onopen = () =>
        this.wsConnection.send(
          JSON.stringify({
            type: "Get Fields",
            access_id: this.$route.params.id,
            user: localStorage.getItem("currentUserImage").trim(),
          })
        );
    });
  },
  watch: {
    connection: function (val) {
      this.wsConnection = val;
    },
    activeUsers: function (val) {
      this.menuUsers = val;
    },
    fields: function (value) {
      this.$nextTick(function () {
        if (this.wsConnection.readyState != 0) {
          this.wsConnection.send(
            JSON.stringify({
              message: {
                type: "Get Fields",
                access_id: this.$route.params.id,
                user: localStorage.getItem("currentUserImage").trim(),
              },
              value: value,
            })
          );
        }
      });
    },
  },
  methods: {
    websocket() {
      if (process.env.NODE_ENV === "development") {
        return "ws://localhost:8080";
      } else {
        return "wss://symple.io:8080";
      }
    },
    initializeFields() {
      let self = this;
      this.$nextTick(function () {
        if (self.activeGroup.length == 0 && self.groupsList.length > 0) {
          $(".list-group .list-group-item").first().addClass("active");
          $(
            `.list-field-item .q-card[data-group="${self.groupsList[0].id}"]`
          ).fadeIn();
        } else {
          $(`.group-input[data-group="${self.activeGroup[1]}"]`)
            .parents(".list-group-item")
            .addClass("active");
          $(
            `.list-field-item .q-card[data-group="${self.activeGroup[1]}"]`
          ).fadeIn();
        }
      });
    },
    setFocusedField(field) {
      this.focusedField = field;
      this.wsConnection.send(
        JSON.stringify({
          type: "Field Focus",
          access_id: this.$route.params.id,
          field: this.focusedField,
          color: localStorage.getItem("currentUserColor"),
          user: localStorage.getItem("currentUserImage").trim(),
          group: $(".list-group-item.active").find(".group-input").val(),
        })
      );
    },
    clearFocusedField(field) {
      this.focusedField = {};
      this.wsConnection.send(
        JSON.stringify({
          type: "Remove Field Focus",
          access_id: this.$route.params.id,
          field: field,
          color: localStorage.getItem("currentUserColor"),
          user: localStorage.getItem("currentUserImage").trim(),
          group: $(".list-group-item.active").find(".group-input").val(),
        })
      );
    },
    changeAnnounce: debounce(function updateWsField(field) {
      this.wsConnection.send(
        JSON.stringify({
          type: "Field Changed",
          access_id: this.$route.params.id,
          field: field,
          fields: this.fields,
        })
      );
    }, 500),
    //Refresh the fields after each tab click to get the latest changes
    onTabClick() {
      this.initializeFields();
    },
    async getPropertyData() {
      try {
        const response = await axios.get(
            `${sympleApi.sympleApiBaseURL()}properties/` +
            this.$route.params.id +
            "/fields",
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
              },
            }
        );
        if (response && response.data && response.data.data && response.data.data.attributes) {
          this.setPropertyData(response.data.data.attributes);
        } else {
          console.log('Response or response data is undefined');
        }
        this.propertiesLoaded = true;
      } catch (error) {
        console.log('Error:', error);
        // set a default value for property in case of error
        this.property = {
          name: "ERROR",
          status: "ERROR",
          siteUrl: "ERROR",
          netlifyWebhook: "ERROR",
          city: "ERROR",
          id: "ERROR",
          logoUrl: "ERROR",
          users: [],
        };
      }
    },
    setPropertyData(attributes) {
      this.property = attributes;
      this.groups = attributes.groups;
    },
    getUsers() {
      if (this.isAdmin) {
        axios
          .get(`${sympleApi.sympleApiBaseURL()}users/`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          })
          .then((response) => {
            this.userOptions = response.data.data.map((property) => {
              return {
                label:
                  property.attributes.first_name +
                  " " +
                  property.attributes.last_name,
                value: property.id,
              };
            });
          })
          .catch((error) => {
            console.log(error);
            if (error.response.data.status === 401)
              // HTTP status code 401 = unauthorized
              console.log("Unauthorized.");
            // Redirect to error page
          });
      }
    },
    addGroup() {
      try {
        let self = this;
        axios
          .post(
            `${sympleApi.sympleApiBaseURL()}groups/`,
            {
              access_id: self.$route.params.id,
              name: "New Group",
              position: 1000,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
              },
            }
          )
          .then((response) => {
            let group = {
              id: parseInt(response.data.group.id),
              name: response.data.group.name,
              position: response.data.group.position,
              fields: {},
            };
            let index = Object.keys(this.groups).length + 1;
            this.groups[index] = group;
          })
          .catch((error) => {
            slackNotifier(error);
            if (error.response.data.status == 401)
              // HTTP status code 401 = unauthorized
              console.log("Unauthorized.");
            // Redirect to error page
          });
      } catch (error) {
        slackNotifier(error);
      }
    },
    addField(field_type) {
      try {
        let self = this;
        axios
          .post(
            `${sympleApi.sympleApiBaseURL()}fields/`,
            {
              access_id: self.$route.params.id,
              group_id: this.activeGroup[1],
              name: "New " + field_type,
              short_name:
                this.activeGroup[0].replace(/\s+/g, "_").toLowerCase() +
                "_new_" +
                field_type.toLowerCase(),
              input_type: field_type,
              position: 1000,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
              },
            }
          )
          .then((response) => {
            let field = {
              id: parseInt(response.data.data.id),
              input_type: response.data.data.attributes.input_type,
              name: response.data.data.attributes.name,
              short_name: response.data.data.attributes.short_name,
              position: response.data.data.attributes.position,
              group: response.data.data.attributes.group_id,
              data: response.data.data.attributes.input.data,
              options: response.data.data.attributes.input.options,
            };
            let newFields = JSON.parse(JSON.stringify(this.fields));
            newFields.push(field);
            this.fields = newFields;
            this.initializeFields();
          })
          .catch((error) => {
            slackNotifier(error);
            if (error.response.data.status == 401)
              // HTTP status code 401 = unauthorized
              console.log("Unauthorized.");
            // Redirect to error page
          });
      } catch (error) {
        slackNotifier(error);
      }
    },
    updateGroups() {
      try {
        let self = this;
        $.each(this.groups, function (index, value) {
          axios
            .patch(
              `${sympleApi.sympleApiBaseURL()}groups/` + value.id,
              {
                access_id: self.$route.params.id,
                name: value.name,
              },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                },
              }
            )
            .catch((error) => {
              slackNotifier(error);
              if (error.response.data.status == 401)
                // HTTP status code 401 = unauthorized
                console.log("Unauthorized.");
              // Redirect to error page
            });
        });
      } catch (error) {
        slackNotifier(error);
      }
    },
    updateFields() {
      try {
        let self = this;
        let formData = new FormData();
        $.each(this.fields, function (index, value) {
          formData = new FormData();
          var params = {};
          switch (value.input_type) {
            case "ImageField":
              params = {
                access_id: self.$route.params.id,
                name: value.name,
                short_name: value.short_name,
                input: {
                  data: value.image[0],
                  alt_text: value.alt_text == undefined ? "" : value.alt_text,
                },
              };
              if (typeof params["input"]["data"] === "string") {
                delete params["input"]["data"];
              }
              Object.entries(params).forEach(([key, value]) => {
                if (key === "input") {
                  for (let data in params[key]) {
                    formData.append(`input[${data}]`, params[key][data]);
                  }
                } else {
                  formData.append(key, value);
                }
              });
              params = formData;
              break;
            case "InputField":
              var options = [];
              var data = [];
              if (value.options.length > 0) {
                Object.keys(value.options).forEach(function (k) {
                  options.push(value.options[k]);
                });
              }
              if (value.data) {
                Object.keys(value.data).forEach(function (k) {
                  data.push(value.data[k]);
                });
              }
              params = {
                access_id: self.$route.params.id,
                name: value.name,
                short_name: value.short_name,
                input: {
                  data: data,
                  input_type: value.type,
                  options: options,
                },
              };
              break;
            default:
              params = {
                access_id: self.$route.params.id,
                name: value.name,
                short_name: value.short_name,
                input: {
                  data: value.data,
                },
              };
          }
          axios
            .patch(
              `${sympleApi.sympleApiBaseURL()}fields/` + value.id,
              params,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                },
              }
            )
            .catch((error) => {
              // slackNotifier(error);
              if (error.response.data.status == 401)
                // HTTP status code 401 = unauthorized
                console.log("Unauthorized.");
              // Redirect to error page
            });
        });
      } catch (error) {
        console.log(error.message);
      }
    },
    publishProperty() {
      this.property.status = "Processing";
      let self = this;
      var params = {
        access_id: self.$route.params.id,
      };

      axios
        .post(
          `${sympleApi.sympleApiBaseURL()}properties/` +
            self.$route.params.id +
            "/publish",
          params,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          }
        )
        .then(() => {})
        .catch((error) => {
          slackNotifier(error);
          if (error.response.data.status == 401)
            // HTTP status code 401 = unauthorized
            console.log("Unauthorized.");
          // Redirect to error page
        });
    },
    updateProperty() {
      let self = this;
      let formData = new FormData();
      var params = {
        access_id: self.$route.params.id,
        name: this.property.name,
        city: this.property.city,
        logo: this.property.logoUrl ? this.property.logoUrl[0] : undefined,
        site_url: this.property.siteUrl,
        netlify_webhook: this.property.netlifyWebhook,
        user_ids: this.property.users,
      };
      if (typeof params["logo"] === "string") {
        delete params["logo"];
      }
      Object.entries(params).forEach(([key, value]) => {
        if (key === "input") {
          for (let data in params[key]) {
            formData.append(`input[${data}]`, params[key][data]);
          }
        } else if (key === "user_ids") {
          for (let data in params[key]) {
            if (typeof params[key][data] === "string" && data > 0) {
              formData.append(`user_ids[]`, params[key][data]);
            } else {
              formData.append(`user_ids[]`, params[key][data].value);
            }
          }
        } else {
          formData.append(key, value);
        }
      });
      axios
        .patch(
          `${sympleApi.sympleApiBaseURL()}properties/` + self.$route.params.id,
          formData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          }
        )
          .then(() => {
            this.$router.push('/properties');
          })
        .catch((error) => {
          slackNotifier(error);
          if (error.response.data.status === 401)
            // HTTP status code 401 = unauthorized
            console.log("Unauthorized.");
          // Redirect to error page
        });
      this.updateGroups();
      this.updateFields();
    },
    formSave() {
      try {
        this.updateProperty();
        Notify.create({
          type: "positive",
          message: "Saved!",
          timeout: 1000,
          color: "positive",
        });
      } catch (error) {
        slackNotifier(error);
        Notify.create({
          type: "negative",
          message: "An error occurred while saving!",
          timeout: 1000,
          color: "negative",
        });
      }
    },
    formPublish() {
      try {
        this.publishProperty();
        Notify.create({
          type: "positive",
          message: "Publishing!",
          timeout: 1000,
          color: "positive",
        });
      } catch (error) {
        slackNotifier(error);
        Notify.create({
          type: "negative",
          message: "An error occurred while publishing!",
          timeout: 1000,
          color: "negative",
        });
      }
    },
    setActiveGroup(group, id) {
      this.activeGroup = [group, id];
    },
    removeField(field) {
      var fields = this.fields;
      var fieldsList = [];
      Object.keys(fields).forEach(function (k) {
        fieldsList.push(fields[k]);
      });
      fieldsList = fieldsList.filter(function (index) {
        return index.id !== field.id;
      });
      axios
        .delete(
          `${sympleApi.sympleApiBaseURL()}fields/` +
            field.id +
            `?access_id=${this.$route.params.id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          }
        )
        .then(() => {
          this.fields = fieldsList;
          Notify.create({
            type: "positive",
            message: "Deleted field!",
            timeout: 1000,
            color: "positive",
          });
        })
        .catch((error) => {
          slackNotifier(error);
          Notify.create({
            type: "negative",
            message: "An error occurred while deleting field!",
            timeout: 1000,
            color: "negative",
          });
          if (error.response.data.status == 401)
            // HTTP status code 401 = unauthorized
            console.log("Unauthorized.");
          // Redirect to error page
        });
    },
    removeGroup(group) {
      var self = this;
      var groups = this.groups;
      var groupsList = [];
      Object.keys(groups).forEach(function (k) {
        groupsList.push(groups[k]);
      });
      groupsList = groupsList.filter(function (index) {
        return index.id !== group.id;
      });
      axios
        .delete(
          `${sympleApi.sympleApiBaseURL()}groups/` +
            group.id +
            `?access_id=${self.$route.params.id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          }
        )
        .then(() => {
          this.groups = groupsList;
          Notify.create({
            type: "positive",
            message: "Deleted group!",
            timeout: 1000,
            color: "positive",
          });
        })
        .catch((error) => {
          console.log(error.response);
          Notify.create({
            type: "negative",
            message: "An error occurred while deleting group!",
            timeout: 1000,
            color: "negative",
          });
          if (error.response.data.status == 401)
            // HTTP status code 401 = unauthorized
            console.log("Unauthorized.");
          // Redirect to error page
        });
      $(".list-group .list-group-item").first().addClass("active");
      $(`.list-field-item .q-card`).hide();
      $(`.list-field-item .q-card[data-group="${groupsList[0].id}"]`).fadeIn();
    },
    removeProperty() {
      axios
        .delete(
          `${sympleApi.sympleApiBaseURL()}properties/` + this.$route.params.id,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          }
        )
        .then(() => {
          this.$router.push("/properties");
        })
        .catch((error) => {
          slackNotifier(error);
          if (error.response.data.status == 401)
            // HTTP status code 401 = unauthorized
            console.log("Unauthorized.");
          // Redirect to error page
        });
    },
    fieldComponent(inputType) {
      switch (inputType) {
        case "TextAreaField":
          return "TextArea";
        case "RichTextField":
          return "RichText";
        case "TextField":
          return "TextLine";
        case "ImageField":
          return "ImageField";
        case "InputField":
          return "InputField";
      }
    },
    updateFieldPositions(fieldIds) {
      let oldStatus = this.property.status;
      this.property.status = "Saving";
      let self = this;
      axios
        .post(
          `${sympleApi.sympleApiBaseURL()}fields/sort/`,
          {
            access_id: self.$route.params.id,
            field_ids: fieldIds,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          }
        )
        .then(() => {
          this.property.status = oldStatus;
        });
    },
  },
  computed: {
    groupsList() {
      var groups = [];
      var rawGroups = this.groups;
      Object.keys(rawGroups)
        .sort(function (a, b) {
          return rawGroups[a].position - rawGroups[b].position;
        })
        .forEach(function (k) {
          groups.push(rawGroups[k]);
        });
      return groups;
    },
    fieldsList() {
      var fieldsList = [];
      $.each(this.groupsList, function (index, group) {
        var fields = JSON.parse(JSON.stringify(group.fields));
        Object.keys(fields)
          .sort(function (a, b) {
            return fields[a].position - fields[b].position;
          })
          .forEach(function (k) {
            fieldsList.push(fields[k]);
          });
      });
      return fieldsList;
    },
    filteredFields: {
      get() {
        let self = this;
        let activeGroup = self.activeGroup[1];
        let fields = this.fields.map((field) => {
          field.active = false;
          return field;
        });
        if (this.activeTab === "All") {
          fields = fields.map((field) => {
            if (field.group === activeGroup) {
              field.active = true;
            }
            return field;
          });
        } else {
          fields = fields.map((field) => {
            if (field.group === activeGroup) {
              if (
                self.activeTab === "Content" &&
                field.name.indexOf("Meta") == -1 &&
                field.name.indexOf("SEO") == -1 &&
                field.input_type != "ImageField"
              ) {
                field.active = true;
              } else if (
                // if the activeTab is set to SEO,
                self.activeTab === "SEO" &&
                // and return each field name in lowercase, but only include the ones with 'seo' in its name
                field.name.toLowerCase().includes("seo") ||
                // also/or return each field name in lowercase, but only include the ones with 'meta' in its name
                field.name.toLowerCase().includes("meta")
              ) {
                //finally, show only those fields
                field.active = true;
              } else if (
                self.activeTab === "Images" &&
                field.input_type === "ImageField"
              ) {
                field.active = true;
              }
            }
            return field;
          });
        }
        return fields;
      },
      set(val) {
        this.fields = val;
        this.updateFieldPositions(val.map((field) => field.id));
      },
    },
    propertyLogo() {
      try {
        if (typeof this.property.logoUrl === "string") {
          return this.property.logoUrl;
        } else {
          var file = this.property.logoUrl[0];
          return URL.createObjectURL(file);
        }
      } catch {
        return "./../assets/images/img-default.png";
      }
    },
    isDisabled() {
      return (
        !this.property.netlifyWebhook || this.property.netlifyWebhook === ""
      );
    },
    dragOptions() {
      return {
        animation: 200,
        group: "field",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
};
</script>
