// filter inputs for Users and Specials 
<template>
  <!-- Specials Filter Input -->
  <q-input
    v-show="$route.name === 'Specials'"
    for="specials-input"
    dense
    outlined
    rounded
    v-model="text"
    placeholder="Filter..."
    class="search-bar"
    v-on:keyup="specialsFilter"
  >
  </q-input>
  <!-- Users Filter Input -->
  <q-input
    v-show="$route.name === 'Users'"
    for="users-input"
    dense
    outlined
    rounded
    v-model="text"
    placeholder="Filter..."
    class="search-bar"
    v-on:keyup="usersFilter"
  >
  </q-input>
</template>

<style lang="scss" scoped>
.rounded-corners {
  border-radius: 16px;
}
.search-bar {
  border-radius: 25px;
}
</style>

<script>
export default {
  methods: {
    specialsFilter: function () {
      var td, td2, td3, td4, i, txtValue, txtValue2, txtValue3, txtValue4;
      var input = document.getElementById("specials-input");
      var filter = input.value.toUpperCase();
      var table = document.getElementById("specials-table");
      var tr = table.getElementsByClassName("special-card");

      // Loop through all table rows, and hide those who don't match the search query
      for (i = 0; i < tr.length; i++) {
        td = tr[i].getElementsByClassName("special-header")[0];
        td2 = tr[i].getElementsByClassName("special-description")[0];
        td3 = tr[i].getElementsByClassName("special-date")[0];
        td4 = tr[i].getAttribute("data-properties");

        if (td) {
          txtValue = td.textContent || td.innerText;
          txtValue2 = td2.textContent || td2.innerText;
          txtValue3 = td3.textContent || td3.innerText;
          txtValue4 = td4;
          if (
            txtValue.toUpperCase().indexOf(filter) > -1 ||
            txtValue2.toUpperCase().indexOf(filter) > -1 ||
            txtValue3.toUpperCase().indexOf(filter) > -1 ||
            txtValue4.toUpperCase().indexOf(filter) > -1
          ) {
            tr[i].style.display = "";
          } else {
            tr[i].style.display = "none";
          }
        }
      }
    },
    usersFilter: function () {
      var input = document.getElementById("users-input");
      var filter = input.value.toUpperCase();
      var table = document.getElementById("users-table");
      var tr = table.getElementsByClassName("user-row");

      // Loop through all table rows, and hide those who don't match the search query
      for (var i = 0; i < tr.length; i++) {
        var tdName = tr[i].getElementsByClassName("user-name")[0];
        var tdCompany = tr[i].getElementsByClassName("user-company")[0];
        var tdEmail = tr[i].getElementsByClassName("user-email")[0];
        var tdLastActive = tr[i].getElementsByClassName("user-last-active")[0];
        var tdStatus = tr[i].getElementsByClassName("user-status")[0];

        var txtName = tdName ? (tdName.textContent || tdName.innerText) : "";
        var txtCompany = tdCompany ? (tdCompany.textContent || tdCompany.innerText) : "";
        var txtEmail = tdEmail ? (tdEmail.textContent || tdEmail.innerText) : "";
        var txtLastActive = tdLastActive ? (tdLastActive.textContent || tdLastActive.innerText) : "";
        var txtStatus = tdStatus ? (tdStatus.textContent || tdStatus.innerText) : "";

        if (
            txtName.toUpperCase().indexOf(filter) > -1 ||
            txtCompany.toUpperCase().indexOf(filter) > -1 ||
            txtEmail.toUpperCase().indexOf(filter) > -1 ||
            txtLastActive.toUpperCase().indexOf(filter) > -1 ||
            txtStatus.toUpperCase().indexOf(filter) > -1
        ) {
          tr[i].style.display = "";
        } else {
          tr[i].style.display = "none";
        }
      }
    }

  },
};
</script>