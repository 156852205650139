<template>
  <div class="search-bar col">
    <!-- <q-input dense standout rounded borderless dark v-model="searchProperty" v-on:keyup="updateSearchProperties" input-class="search-input">
      <template v-slot:prepend>
        <q-icon src="../../assets/images/icons/icon-search.svg" color="primary" size="1rem">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17.5738 15.4797L13.4353 11.2853C14.1384 10.1519 14.5461 8.81109 14.5461 7.37201C14.5461 3.30051 11.2895 0 7.27228 0C3.25503 0 0 3.30051 0 7.37201C0 11.4435 3.25654 14.744 7.2738 14.744C8.81948 14.744 10.25 14.2541 11.4274 13.4232L15.5159 17.5684C15.8008 17.8556 16.1721 18 16.5449 18C16.9176 18 17.2889 17.8572 17.5738 17.5684C18.1421 16.9894 18.1421 16.0556 17.5738 15.4797ZM7.2738 12.3543C4.55825 12.3543 2.35792 10.1227 2.35792 7.37201C2.35792 4.62133 4.55825 2.38823 7.2738 2.38823C9.98935 2.38823 12.1897 4.6198 12.1897 7.37048C12.1897 10.1212 9.98783 12.3543 7.2738 12.3543Z" fill="#00A3FF"/>
          </svg>
        </q-icon>
      </template>
    </q-input> -->
    <q-input dense outlined v-model="searchProperty" v-on:keyup="updateSearchProperties" width="300">
      <template v-slot:prepend>
        <q-icon src="../../assets/images/icons/icon-search.svg" color="primary" size="1rem">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17.5738 15.4797L13.4353 11.2853C14.1384 10.1519 14.5461 8.81109 14.5461 7.37201C14.5461 3.30051 11.2895 0 7.27228 0C3.25503 0 0 3.30051 0 7.37201C0 11.4435 3.25654 14.744 7.2738 14.744C8.81948 14.744 10.25 14.2541 11.4274 13.4232L15.5159 17.5684C15.8008 17.8556 16.1721 18 16.5449 18C16.9176 18 17.2889 17.8572 17.5738 17.5684C18.1421 16.9894 18.1421 16.0556 17.5738 15.4797ZM7.2738 12.3543C4.55825 12.3543 2.35792 10.1227 2.35792 7.37201C2.35792 4.62133 4.55825 2.38823 7.2738 2.38823C9.98935 2.38823 12.1897 4.6198 12.1897 7.37048C12.1897 10.1212 9.98783 12.3543 7.2738 12.3543Z" fill="#00A3FF"/>
          </svg>
        </q-icon>
      </template>
    </q-input>
    <div class="search-options" style="display: none;">
      <router-link class='option' v-for="property in searchProperties" :key="property.accessId" :to="`/properties/${property.accessId}/edit`">
        <div class="row" v-on:click="this.searchProperty = ''">
          <i class="fa fa-circle q-mr-sm" style="display: none;"></i>
          <span class="prop-text">{{ property.name }}</span>
        </div>
      </router-link>
    </div>
  </div>
</template>
<script>
import $ from 'jquery';
import axios from "axios";
import sympleApi from '@/api/symple-api'
import { slackNotifier } from '../helpers/helpers.js'

export default {
  name: "SearchBar",
  display: "Transitions",
  data() {
    return {
      properties: [],
      searchProperty: '',
      searchProperties: []
    };
  },
  mounted() {
    axios
      .get(
        `${sympleApi.sympleApiBaseURL()}properties/`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      )
      .then((response) => {
        this.properties = response.data.data
      })
      .catch((error) => {
        slackNotifier(error);
        if (error.response.data.status == 401)
          // HTTP status code 401 = unauthorized
          console.log("Unauthorized.");
        // Redirect to error page
      })
  },
  methods:{
    updateSearchProperties() {
      var searchValues = []
      var properties = this.propertyNameList
      var searchProperty = this.searchProperty
      if (searchProperty != '') {
        $.each(properties, function (index, property) {
          var name = property.name
          if (name.toLowerCase().includes(searchProperty.toLowerCase())) {
            searchValues.push(property)
          }
        })
      }
      if (searchValues.length > 0) {
        $('.search-options').fadeIn()
      } else {
        $('.search-options').hide()
      }
      this.searchProperties = searchValues
    },
  },
  computed: {
    propertyNameList() {
      var names = []
      var properties = this.properties
      Object.keys(properties).sort(function(a, b) {
          return properties[a].position - properties[b].position
        }).forEach(function(k) {
          names.push({ accessId: properties[k].attributes.access_id, name: properties[k].attributes.name });
        });
      return names
    }
  }
};
</script>