<template>
  <div class="items-center">
    <q-card
      v-for="special in sortedSpecials"
      :key="special.id"
      class="special-card rounded-corners no-shadow  q-my-lg q-py-sm"
      :data-status="special.attributes.status"
      :data-properties="filterProps(JSON.parse(JSON.stringify(special.attributes.properties)))"
    >
      <q-card-section class="q-px-28">
        <!-- Header -->
        <div class="text-bold q-pt-sm q-pb-xs row">
          <div class="col-10 col-md-12">
            <span class="special-header">
              {{ special.attributes.header }}
            </span>
          </div>
          <!-- Mobile status badges -->
          <div class="col">
            <!-- Active mobile badge -->
            <q-badge
              v-if="special.attributes.status == 0"
              class="q-px-sm q-py-xs lt-md float-right status-badge"
              :id="'active-status-mobile-' + special.attributes.id"
              align="middle"
              color="positive"
            >
              ACTIVE
            </q-badge>
            <!-- Paused mobile badge -->
            <q-badge
              v-if="special.attributes.status == 1"
              class="q-px-sm q-py-xs lt-md float-right status-badge"
              :id="'paused-status-mobile-' + special.attributes.id"
              align="middle"
              color="accent"
            >
              PAUSED
            </q-badge>
            <!-- Expired mobile badge -->
            <q-badge
              v-if="special.attributes.status == 2"
              class="q-px-sm q-py-xs lt-md float-right status-badge"
              :id="'expired-status-mobile-' + special.attributes.id"
              align="middle"
              color="negative"
            >
              EXPIRED
            </q-badge>
          </div>
        </div>

        <!-- Description -->
        <div class="text-subtitle2 q-pt-none q-pb-sm">
          <span class="special-description">
          {{ special.attributes.description }}
          </span>
        </div>
        <div
          class="q-pb-sm special-card-bottom"
        >
          <!-- Active Status Span (Visual Pause) START -->
          <span
          v-show="special.attributes.status==0"
          :id="'active-status-' + special.attributes.id"
          >
            <q-badge
              class="q-px-sm q-py-xs q-mr-md gt-sm status-badge"
              align="middle"
              color="positive"
            >
              ACTIVE
            </q-badge>
            <span class="text-positive vertical-middle">
              <q-icon name="far fa-calendar q-mr-sm" color="positive"></q-icon>
              <span v-if="typeof special.attributes.start_date !== undefined && special.attributes.end_date !== undefined" class="vertical-middle special-date">
                {{ formatDate(special.attributes.start_date) }} -
                {{ formatDate(special.attributes.end_date) }}
                <span v-if="special.attributes.end_date == undefined" class="vertical-middle special-ongoing">
                Ongoing
                </span>
              </span>
            </span>
          </span>
          <!-- Active Status Span (Visual Pause) END -->
          <!-- Paused Status Span (Visual Resume) START -->
          <span
          v-show="special.attributes.status==1"
          :id="'paused-status-' + special.attributes.id"
          style="display:none"
          >
            <q-badge
              class="q-px-sm q-py-xs q-mr-md gt-sm status-badge"
              align="middle"
              color="accent"
            >
              PAUSED
            </q-badge>
            <span class="text-accent vertical-middle">
              <q-icon name="far fa-calendar q-mr-sm" color="accent"></q-icon>
              <span v-if="typeof special.attributes.start_date !== undefined && special.attributes.end_date !== undefined" class="vertical-middle special-date">
                {{ formatDate(special.attributes.start_date) }} -
                {{ formatDate(special.attributes.end_date) }}
                <span v-if="special.attributes.end_date == undefined" class="vertical-middle special-ongoing">
                Ongoing
                </span>
              </span>
            </span>
          </span>  
          <!-- Paused Status Span (Visual Resume) END -->
          <!-- Expired Status Span (Visual Resume) START -->
          <span
          v-show="special.attributes.status==2"
          :id="'expired-status-' + special.attributes.id"
          style="display:none"
          >
            <q-badge
              class="q-px-sm q-py-xs q-mr-md gt-sm status-badge"
              align="middle"
              color="negative"
            >
              EXPIRED
            </q-badge>
            <span class="text-accent vertical-middle">
              <q-icon name="far fa-calendar q-mr-sm" color="negative"></q-icon>
              <span v-if="typeof special.attributes.start_date !== undefined && special.attributes.end_date !== undefined" class="vertical-middle special-date text-negative">
                {{ formatDate(special.attributes.start_date) }} -
                {{ formatDate(special.attributes.end_date) }}
              </span>
            </span>
          </span> 
          <!-- Expired Status Span (Visual Resume) END -->
          <span class="float-right">
            <!-- Less than 5 properties (not 1 not 0) -->
            <span
              v-if="
                Object.keys(special.attributes.properties).length != 0 &&
                Object.keys(special.attributes.properties).length != 1 &&
                Object.keys(special.attributes.properties).length < 5
              "
            >
              <span class="text-grey-5 q-mr-md gt-sm vertical-middle">
                Assigned to
                {{
                  Object.keys(special.attributes.properties).length
                }}
                properties</span
              >
              <span class="text-grey-5 q-mr-md lt-md vertical-middle">
                <i class="far fa-building"></i>
                {{ Object.keys(special.attributes.properties).length }}
              </span>
            </span>
            <!-- Equals to 0 properties -->
            <span
              v-if="Object.keys(special.attributes.properties).length === 0"
            >
              <span class="text-grey-5 q-mr-md gt-sm vertical-middle">
                Not assigned to any properties
              </span>
              <span class="text-grey-5 q-mr-md lt-md vertical-middle">
                <i class="far fa-building"></i> 0
              </span>
            </span>
            <!-- Equals to 1 property -->
            <span
              v-if="Object.keys(special.attributes.properties).length === 1"
            >
              <span class="text-grey-5 q-mr-md gt-sm vertical-middle">
                Assigned to 1 property
              </span>
              <span class="text-grey-5 q-mr-md lt-md vertical-middle">
                <i class="far fa-building"></i> 1
              </span>
            </span>
            <!-- More than 5 properties -->
            <span v-if="Object.keys(special.attributes.properties).length > 4">
              <span class="text-grey-5 q-mr-md gt-sm vertical-middle">
                Assigned to 5+ properties</span
              >
              <span class="text-grey-5 q-mr-md lt-md vertical-middle">
                <i class="far fa-building"></i> 5+
              </span>
            </span>

            <q-btn
              class="q-mr-xs special-action text-dark edit-icon"
              padding="5px"
              icon="fas fa-edit"
              size="xs"
              @click="toggleRightDrawer2(special)"
            />
            <q-btn
              v-show="special.attributes.status==0"
              class="q-mr-xs special-action text-dark edit-icon-pause"
              :id="'pause-btn-' + special.attributes.id"
              padding="5px"
              icon="fas fa-pause"
              size="xs"
              @click="simulatePause(special)"
            />
            <q-btn
              v-show="special.attributes.status==1"
              class="q-mr-xs special-action text-dark edit-icon-play"
              :id="'play-btn-' + special.attributes.id"
              padding="5px"
              icon="fas fa-play"
              size="xs"
              @click="simulateResume(special)"
            />
            <q-btn
              class="special-action text-dark edit-icon"
              style="padding-left: 5px !important"
              padding="5px"
              icon="fas fa-trash"
              size="xs"
              @click="toggleDeleteSpecialModal(special)"
            />
          </span>
        </div>
      </q-card-section>
    </q-card>

    <!-- EDIT SPECIAL FORM START -->
      <q-drawer v-model="rightDrawerOpen2" side="right" overlay bordered>
        <q-scroll-area class="fit">
        <!-- Edit Special Contents  -->
        <q-item-section class="q-pt-md q-pl-md">
          <q-toolbar class="q-pl-none">
            <span class="text-h6 text-left q-pb-xs">Edit Special</span>
            <q-space />
            <q-icon
              @click="toggleRightDrawer2"
              class="q-pb-xl q-pl-lg"
              name="fas fa-times"
              style="color: #c1c1c1"
              size="20px"
            ></q-icon>
          </q-toolbar>
        </q-item-section>
        <!-- Edit Special Form  -->
        <q-item-section class="q-pa-md">
        <edit-special :special="specialEdit" :properties="propertySelect" :groups="groupSelect" @updateSpecials="updateSpecials" @updateGroupsParams="updateGroups" @closeEdit="toggleRightDrawer2"></edit-special>
        </q-item-section>
        </q-scroll-area>
      </q-drawer>
    <!-- EDIT SPECIAL FORM END -->
    
    <!-- Delete Modal  -->
    <q-dialog v-model="deleteSpecialModal" persistent>
      <q-card class="q-pa-lg symple-corners">
        <q-card-section class="q-mb-lg">
          <div class="row justify-center q-mb-md">
            <span
              ><i class="fa fa-2x fa-exclamation-triangle text-negative"></i
            ></span>
          </div>
          <div class="row items center">
            <span class="q-ml-sm"
              >Are you sure you want to delete this special?
            </span>
          </div>
        </q-card-section>
        <q-card-actions align="center">
          <q-btn 
            outline
            label="Yes"
            color="positive"
            @click="deleteSpecial"
            v-close-popup
            class="symple-corners"
          />
          <q-btn label="No" color="negative" v-close-popup class="symple-corners"/>
        </q-card-actions>
      </q-card>
    </q-dialog>
    <!-- end delete modal  -->
  </div>
</template>

<style lang="scss" scoped>
.rounded-corners {
  border-radius: 16px;
}
.special-card {
  background: #ffffff;
  border: 2px solid #cacfea;
  border-radius: 16px;
}
.special-action {
  border: 2px solid black;
  border-color: #cacfea;  
}
.special-header {
  font-size: 18px;

}

.q-input {
  background: #ffffff;
  border: 2px solid #cacfea;
  box-sizing: border-box;
  border-radius: 50px;
  // padding-left: 10px !important;
}
.add-special {
  border-radius: 50px;
}
.fas.fa-plus {
  font-size: 14px !important;
}
</style>

<script>
import { ref } from "vue";
import axios from "axios";
import EditSpecial from "./EditSpecial.vue"; 
import Vue2Filters from 'vue2-filters'
import sympleApi from '@/api/symple-api'
import { slackNotifier } from '../helpers/helpers.js'

export default {
  mixins: [Vue2Filters.mixin],
  name: "SpecialData",
  components: { EditSpecial },
  props: ['specials', 'properties', 'groups'],
  setup() {
    const rightDrawerOpen2 = ref(false);
    return {
      rightDrawerOpen2
    };
  },
  data() {
    return {
      deleteSpecialModal: ref(false),
      specialEdit: '',
      specialData: this.specials,
      propertySelect: this.properties,
      groupSelect: this.groups,
      initialLoad: true
    };
  },
  watch:{
    specials() {
      this.initialLoad = true;
      this.specialData= this.specials;
      this.$nextTick(function () {
        this.setInitialPauseStates()
        this.initialLoad = false;
      })
    },
    properties() {
      this.propertySelect = this.properties;
    },
    groups() {
      this.groupSelect = this.groups;
    }
  },
  methods: {
    filterProps(properties) {
      var names = []

      Object.keys(properties).forEach(function(key) {
        names.push(properties[key].name);
      });
      
      return names
    },
    deleteSpecial() {
      axios
        .delete(`${sympleApi.sympleApiBaseURL()}specials/` + this.specialEdit.attributes.id, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        })
        .then(() => {
          this.$emit("updateSpecials");
          //Refresh specials data
        })
        .catch((error) => {
          slackNotifier(error);
        })
    },
    toggleDeleteSpecialModal(selected) {
      this.specialEdit=selected;
      this.deleteSpecialModal = true;
    },
    toggleRightDrawer2(selected) {
      this.deleteSpecialModal = false
      if(!this.rightDrawerOpen2) {
        this.specialEdit=selected
        this.updateGroups(Object.values(this.specialEdit.attributes.properties).map(property => {
            return {
              label: property.name,
              value: property.id
            }
          }))
      }
      this.rightDrawerOpen2 = !this.rightDrawerOpen2;
    },
    formatDate(oldDate) {
      if(oldDate) {
        let date = new Date(oldDate);
        date.setMinutes(date.getMinutes()+date.getTimezoneOffset())
        let shortMonth = date.toLocaleString('en-us', { month: 'short' })
        let numericDate = date.toLocaleString('en-us', { day: 'numeric' })
        let numericYear = date.toLocaleString('en-us', { year: 'numeric' })
        let newDate = (shortMonth + ' ' + numericDate + ', ' + numericYear)
        return(newDate)
      }
      else {
        return "";
      }
    },
    updateSpecials() {
      this.$emit("updateSpecials");
    },
    updateGroups(value) {
      this.$emit("updateGroupsParams", value);
    },
    closeAdd() {
      this.$emit("closeAdd");
    },
    simulatePause(special) {  
      var pauseBtn = document.getElementById("pause-btn-"+special.id);
      var playBtn = document.getElementById("play-btn-"+special.id);
      var activeStatus = document.getElementById("active-status-"+special.id);
      var pausedStatus = document.getElementById("paused-status-"+special.id);
      var expiredStatus = document.getElementById("expired-status-"+special.id);
      if (special.attributes.status == 2) {
        expiredStatus.style.display="";
        pauseBtn.style.display = "none";
        playBtn.style.display = "none";
        activeStatus.style.display = "none";
        pausedStatus.style.display = "none";
      }
      else {
        pauseBtn.style.display = "none";
        playBtn.style.display = "";
        activeStatus.style.display = "none";
        pausedStatus.style.display = "";
        if(!this.initialLoad) {
          this.togglePause(special)
        }
      }
    },
    simulateResume(special) {
      var pauseBtn = document.getElementById("pause-btn-"+special.id);
      var playBtn = document.getElementById("play-btn-"+special.id);
      var activeStatus = document.getElementById("active-status-"+special.id);
      var pausedStatus = document.getElementById("paused-status-"+special.id);
      pauseBtn.style.display = "";
      playBtn.style.display = "none";
      activeStatus.style.display = "";
      pausedStatus.style.display = "none";
      if(!this.initialLoad) {
        this.togglePause(special)
      }
    },
    setInitialPauseStates() {
      for(let i=0;i<this.specialData.length;i++){
        if(this.specialData[i].attributes.status===0) {
          this.simulateResume(this.specialData[i])
        }
        else if(this.specialData[i].attributes.status===1) {
          this.simulatePause(this.specialData[i])
        }
        else if(this.specialData[i].attributes.status===2) {
          this.simulatePause(this.specialData[i])
        }
      }
    },
    togglePause(special){
      if(special.attributes.status===0)
      {
        special.attributes.status=1
        
      } else {
        special.attributes.status=0
      }
      axios
        .put(`${sympleApi.sympleApiBaseURL()}specials/` + special.id, { status: special.attributes.status }, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        })
        .then(() => {
          this.updateSpecials();
        })
        .catch((error) => {
          slackNotifier(error);
        });
    }
  },
    computed: {
      sortedSpecials() {
        let data = this.specialData.slice()
        const sorter = (a,b) => a.attributes.status - b.attributes.status || new Date(b.attributes.updated_at) - new Date(a.attributes.updated_at)
        console.log(data.sort(sorter))
        return data.sort(sorter)
      }
  }
};
</script>