<template>
  <div v-if="currentUser" class="row justify-center content q-pa-xl">
    <q-card class="settings-card" flat borderless>
      <!-- <q-item>
        <q-item-section avatar>
          <q-avatar>
            <img :src="profileImageUrl" />
          </q-avatar>
        </q-item-section>

        <q-item-section>
          <q-item-label>{{ currentUser.attributes.first_name }}</q-item-label>
          <q-item-label caption>
            {{ currentUser.attributes.company }}
          </q-item-label>
        </q-item-section>
      </q-item> -->

      <!-- <q-separator style="background-color: #cacefa !important" /> -->

      <q-card-section horizontal>
        <q-card-section class="col-xs-12 col-sm-12 col-md-6">
          <q-list class="q-px-md q-pt-lg settings-input-list">
            <q-form
              @submit="submitSettings"
              @reset="onReset"
              class="q-gutter-md settings-form"
            >
              <label for="users" class="input-label text-bold"
                >First Name</label
              >
              <q-input
                v-model="currentUser.attributes.first_name"
                no-error-icon
                dense
                outlined               
                class="q-mt-xs"
                lazy-rules
                :rules="[(val) => val && val.length > 0]"
              />
              <label for="users" class="input-label text-bold"
                >Last Name</label
              >
              <q-input
                v-model="currentUser.attributes.last_name"
                no-error-icon
                dense
                outlined               
                class="q-mt-xs"
                lazy-rules
                :rules="[(val) => val && val.length > 0]"
              />
              <label for="users" class="input-label text-bold">Email</label>
              <q-input
                v-model="currentUser.attributes.email"
                no-error-icon
                dense
                outlined               
                class="q-mt-xs"
                lazy-rules
                :rules="[(val) => val && val.length > 0]"
              />
              <label for="users" class="input-label text-bold">Password</label>
              <q-input
                v-model="currentUser.attributes.password"
                no-error-icon
                dense
                outlined               
                class="q-mt-xs"
                lazy-rules
                :rules="[(val) => val && val.length > 0]"
              />
              <label for="users" class="input-label text-bold"
                >Confirm Password</label
              >
              <q-input
                v-model="currentUser.attributes.password"
                no-error-icon
                dense
                outlined               
                class="q-mt-xs"
                lazy-rules
                :rules="[(val) => val && val.length > 0]"
              />
              <label for="users" class="input-label text-bold">Company</label>
              <q-input
                v-model="currentUser.attributes.company"
                no-error-icon
                dense
                outlined               
                class="q-mt-xs"
                lazy-rules
                :rules="[(val) => val && val.length > 0]"
              />
              <!-- Show this section on small screens only -->
              <div class="lt-md">
                <!-- Profile Image -->
                <label for="users" class="input-label text-bold"
                  >Profile Image</label
                >
                <!-- <DropZone class="dropzone-space" @drop.prevent="drop" @change="selectedFile"/>
                <span class="q-ml-sm text-grey">{{ dropzoneFile.name }} <i class="fa fa-check-circle text-positive img-success" v-if="dropzoneFile"></i></span> -->
                <q-input
                  @update:model-value="
                    (val) => {
                      avatar = val[0];
                    }
                  "
                  dense
                  borderless
                  v-model="currentUser.attributes.avatar_url"
                  type="file"
                  class="q-mt-sm q-mb-xs text-muted input-space"
                  style="margin-left: -13px"
                />
                <q-img
                  class="q-mb-lg settings-img"
                  :src="profileImageUrl"
                >
                </q-img>
              </div>
            </q-form>
          </q-list>
        </q-card-section>

        <q-separator
          vertical
          class="gt-sm"
        />

        <q-card-section class="q-pa-xl full-width gt-sm">
          <!-- Profile Image -->
          <label for="users" class="input-label text-bold">Profile Image</label>
          <!-- <div>
            <DropZone class="dropzone-space" @drop.prevent="drop" @change="selectedFile"/>
            <span class="q-ml-sm text-grey">{{ dropzoneFile.name }} <i class="fa fa-check-circle text-positive img-success" v-if="dropzoneFile"></i></span>
          </div> -->
          <q-input
            @update:model-value="
              (val) => {
                avatar = val[0];
              }
            "
            dense
            borderless
            v-model="currentUser.attributes.avatar_url"
            type="file"
            class="q-mt-sm q-mb-xs text-muted input-space"
            style="margin-left: -13px"
          />
          <q-img
            class="q-mb-lg settings-img"
            :src="profileImageUrl"
          >
          </q-img>
        </q-card-section>
      </q-card-section>
      <!-- Save button Bottom Bar  -->
     <!-- <q-separator style="background-color: #cacefa !important;"/> -->
      <q-card-section 
        class="settings-save-area"
        >
        <div class="row justify-center">
          <q-btn
            style="width:250px!important;"
            type="submit"
            color="positive"
            class="full-width text-bold"
            @click="formSave(); simulateProgress(1);"
            :loading="loading[1]"
          >
            Save
            <template v-slot:loading>
              <q-spinner class="on-left"></q-spinner>
                Saving...
            </template>
          </q-btn>
        </div>
      </q-card-section>
      <!-- End Save button bottom bar  -->
    </q-card>
  </div>
</template>
<style lang="scss" scoped>
.dropzone {
    width: 100%;
    height: 120px;
    margin-top: 5px;
    .img-upload-icon {
      width: 8% !important;
    }
}
.settings-card {
  width: 100%;
  max-width: 800px;
  border: 1px solid #cacefa;
  border-radius: 5px;
}
.settings-form {
  min-width: 300px;
}
</style>
<script>
import { Notify } from 'quasar'
import { ref } from "vue";
import axios from "axios";
// import DropZone from "../fields/DropZone.vue";
import sympleApi from '@/api/symple-api'
import { slackNotifier } from '../helpers/helpers.js'

export default {
  name: "SettingsForm",
  props: ["user"],
  components: { 
    // DropZone
  },
  setup() {
    let dropzoneFile = ref("");
    const drop = (e) => {
      dropzoneFile.value = e.dataTransfer.files[0];
    };
    const selectedFile = () => {
      dropzoneFile.value = document.querySelector(".dropzoneFile").files[0];
    };
    const avatar = ref(null);
    const loading = ref([
      false,
      false,
      false,
      false,
      false,
      false
    ]);
    const progress = ref(false)
    function simulateProgress (number) {
        // we set loading state
        loading.value[ number ] = true
        // simulate a delay
        setTimeout(() => {
        // we're done, we reset loading state
        loading.value[ number ] = false
        }, 3000)
      }
    return {
      loading,
      progress,
      simulateProgress,
      avatar,
      drop,
      selectedFile,
      dropzoneFile,
    };
   
    
  },
  data() {
    return {
      currentUser: ref(null),
    };
  },
  watch: {
    user() {
      this.currentUser = this.user;
    },
  },
  methods: {
    submitSettings() {
      let formData = new FormData();
      Object.entries(this.currentUser.attributes).forEach(([key, value]) =>
        formData.append(key, value)
      );
      if(this.avatar) {
        formData.append('avatar_url', this.avatar);
      }
      else {
        formData.delete('avatar_url')
      }
      axios
        .put(
          `${sympleApi.sympleApiBaseURL()}users/` + this.currentUser.id,
          formData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          }
        )
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          slackNotifier(error);
        });
    },
    formSave() {
      try {
        this.submitSettings()
        Notify.create({
          type: 'positive',
          message: 'Saved!',
          timeout: 1000,
          color: 'positive'
        })
      } catch(error) {
        slackNotifier(error)
        Notify.create({
          type: 'negative',
          message: 'An error occurred while saving!',
          timeout: 1000,
          color: 'negative'
        })
      }
    },
  },
  computed: {
    profileImageUrl() {
      let avatarUrl = ""
      if(this.avatar) {
        avatarUrl = URL.createObjectURL(this.avatar);
      }
      else if (this.currentUser.attributes.avatar_url.medium.url) {
        avatarUrl = this.currentUser.attributes.avatar_url.medium.url
      }
      else {
        avatarUrl = require('../../assets/images/avatar-default.png')
      }
      return avatarUrl
    }
  }
};
</script>