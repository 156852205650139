<template>
  <draggable
    class="list-group"
    tag="div"
    :component-data="{
      tag: 'div',
      type: 'transition-group',
      name: !drag ? 'flip-list' : null
    }"
    :list="groupList"
    :disabled="!isAdmin"
    v-bind="dragOptions"
    @change="reOrderGroupPositions"
    item-key="position"
  >
    <template #item="{ element }">
      <q-item-section v-on:click="updateFields(element.name, element.id)" class="list-group-item justify-center">
        <q-item dense class="q-my-none q-pa-none q-px-lg q-mx-md text-subtitle2 items-center group-hover">
          <i class="fa fa-circle q-mr-sm" style="display: none;"></i>
          <i aria-hidden="true"></i>
          <q-input 
            :data-group="element.id"
            input-class="group-input" 
            v-model="element.name" 
            borderless
            dense
            :size="element.name.length + 1"
            v-on:keyup="updateShortNames(element)"
          />
          <q-space />
          <i class="fa fa-grip-lines handle text-muted " style="display: none;"></i>
          <i v-if="isAdmin" class="fas fa-times close-group q-pl-sm" style="color: #e2e2e2; display: none;" @click="showDeleteModal(element)"></i>
        </q-item>
      </q-item-section>
    </template>
  </draggable>
  <!-- Delete Modal  -->
  <q-dialog v-model="deleteGroup" persistent>
    <q-card 
    class="q-pa-lg"
    style="border-radius: 18px; border: 1px solid #cacefa;">
      <q-card-section class="q-mb-lg">
        <div class="row justify-center q-mb-md">
          <span
            ><i class="fa fa-2x fa-exclamation-triangle text-negative"></i
          ></span>
        </div>
        <div class="row items center">
          <span class="q-ml-sm"
            >Are you sure you want to delete this group?
          </span>
        </div>
      </q-card-section>
      <q-card-actions align="center">
        <q-btn outline label="Yes" color="positive" @click="removeGroup()" />
        <q-btn label="No" color="negative" v-close-popup />
      </q-card-actions>
    </q-card>
  </q-dialog>
  <!-- end delete modal  -->
</template>

<style lang="scss" global>
.group-tab {
  max-height: 40px;
}

.group-hover:hover {
  background: #00a2ff1a;
  i.fa-grip-lines, i.fa-times {
    color: var(--q-secondary) !important;
    opacity: 0.5;
  }
}

.list-group-item {
  &.active {
    i.fa-circle {
      color: var(--q-primary) !important;
      display: block !important;
      font-size: 10px;
      margin-right: 15px;
    }
  }
  .q-item--dense {
    max-height: 40px;
  }

  &:hover > div {
    .handle, .close-group {
      display: block !important;
    }
  }

  .group-input {
    font-size: 14px;
    margin: 10px 0px;
    // padding: 10px 0px !important;
    font-weight: 700;

    &:hover {
      border-bottom: 1px solid #cacfea;
    }

    &:focus {
      border-bottom: 1px solid #00a3ff;
    }
  }
}
</style>

<script>
import axios from "axios";
import $ from 'jquery'
import draggable from "vuedraggable";
import { ref } from "vue";
import { useStore } from "vuex";
import sympleApi from '@/api/symple-api'
import { slackNotifier } from '../helpers/helpers.js'

export default {
  name: "Groups",
  display: "Transitions",
  emits: ["activeGroup", "removeGroup"],
  groupOrder: 7,
  components: {
    draggable
  },
  setup() {
    return {
      deleteGroup: ref(false),
    };
  },
  props: ['groups'],
  data() {
    var isAdmin = useStore().state.admin
    return {
      isAdmin,
      drag: false,
      groupList: this.groups,
      groupId: 0,
      group: {},
      groupPosition: 0
    };
  },
  mounted() {
    this.reOrderGroupPositions()
  },
  updated() {
    this.groupList = this.groups
  },
  watch: {
    groups: function(val) {
      this.groupList = val
    }
  },
  methods: {
    reOrderGroupPositions(event) {
      try {
        let self = this;
        $.each(this.groupList, function(index, value) {
          var id = value.id
          value.position = index + 1
          axios
            .patch(
              `${sympleApi.sympleApiBaseURL()}groups/` + id,
              {
                access_id: self.$route.params.id,
                position: value.position
              },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                },
              }
            )
            .catch((error) => {
              slackNotifier(error);
              if (error.response.data.status == 401)
                // HTTP status code 401 = unauthorized
                console.log("Unauthorized.");
              // Redirect to error page
            });
        });
        this.$nextTick(()=>{
          if (event) {
            if($(`.group-input[data-group="${this.groupList[event.moved.oldIndex].id}"]`).parents('.list-group-item').hasClass("active")) {
              $(`.group-input[data-group="${this.groupList[event.moved.oldIndex].id}"]`).parents('.list-group-item').removeClass("active")
              $(`.group-input[data-group="${this.groupList[event.moved.newIndex].id}"]`).parents('.list-group-item').addClass("active")
            }
            else if($(`.group-input[data-group="${this.groupList[event.moved.newIndex].id}"]`).parents('.list-group-item').hasClass("active")) {
              $(`.group-input[data-group="${this.groupList[event.moved.newIndex].id}"]`).parents('.list-group-item').removeClass("active")
              if(event.moved.newIndex>event.moved.oldIndex)
                $(`.group-input[data-group="${this.groupList[event.moved.newIndex-1].id}"]`).parents('.list-group-item').addClass("active")
              else
                $(`.group-input[data-group="${this.groupList[event.moved.newIndex+1].id}"]`).parents('.list-group-item').addClass("active")
            }
          }
        });
      } catch(error) {
        slackNotifier(error)
      }
    },
    updateFields(name, id) {
      $(`.list-group-item`).removeClass('active')
      $(`.list-group-item .group-input[data-group="${id}"`).parents('.list-group-item').addClass('active')
      $(`.list-field-item .q-card`).hide()
      $(`.list-field-item .q-card[data-group="${id}"]`).fadeIn()
      this.$emit('activeGroup', name, id)
    },
    showDeleteModal(group) {
      this.deleteGroup = true; 
      this.groupId = group.id
      this.group=group
      this.groupPosition = group.position
    },
    removeGroup() {
      let self = this;
      this.$emit('removeGroup', this.group)
      this.groupList = this.groupList.filter(function (index) {
        return index.id !== self.group.id;
      });
      this.deleteGroup = false;
      this.reOrderGroupPositions()
    },
    updateShortNames(group) {
      $.each(group.fields, function (index, field) {
        field.short_name = `${group.name.toLowerCase()} ${field.name.toLowerCase()}`.replace(/ /g, '_')
      });
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "group",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  }
};
</script>

<style>
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.3;
  background: #cacefa;
  border-right: 3px solid #00a3ff;
  border-radius: 0;
}
.list-group-item {
  cursor: pointer;
}
.list-group-item i {
  cursor: pointer;
}
.q-item__section--main + .q-item__section--main {
  margin: 0 !important;
}
</style>