import { createRouter, createWebHistory } from 'vue-router'
// import { store } from '../store/store.js'
// 1. Define route components.
// These can be imported from other files
import Login from '../views/Login.vue'
import Properties from '../views/Properties.vue'
import Edit from '../views/Edit.vue'
import Users from '../views/Users.vue'
import ForgotPassword from "../views/ForgotPassword.vue"
import PasswordReset from "../views/PasswordReset.vue"
import Specials from '../views/Specials.vue'
import Settings from '../views/Settings.vue'

function websocket() {
    if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'localhost') {
      return "ws://localhost:8080";
    } else if (window.location.href.indexOf("beta") > -1) {
      return "wss://beta.symple.io:8080";
    } else {
      return "wss://symple.io:8080";
    }
}

// 2. Define some routes
// Each route should map to a component.
const routes = [
    {
        path: '/',
        name: 'Login',
        component: Login,
        beforeEnter: (a, x, next) => {
            if (localStorage.getItem("authToken")) next({ name: 'Properties' })
            else next()
        }
    },
    {
        path: '/forgot-password',
        name: 'Forgot Password',
        component: ForgotPassword
    },
    {
        path: '/password_reset/',
        name: 'Password Reset',
        component: PasswordReset,
         props: (route) => ({ token: route.query.token }),
    },
    {
        path: '/properties',
        name: 'Properties',
        component: Properties,
        beforeEnter: (a, x, next) => {
            if (!localStorage.getItem("authToken")) next({ name: 'Login', params: { unauthorized: true }})
            else {
                next()
            }
        }
    },
    {
        path: '/properties/:id/edit',
        name: 'Edit',
        component: Edit,
        beforeEnter: (a, x, next) => {
            if (!localStorage.getItem("authToken")) next({ name: 'Login', params: { unauthorized: true }})
            else next()
        }
    },
    {
        path: '/users',
        name: 'Users',
        component: Users,
        beforeEnter: (a, x, next) => {
            if (!localStorage.getItem("authToken")) next({ name: 'Login', params: { unauthorized: true }})
            else next()
        }
    },
    {
        path: '/specials',
        name: 'Specials',
        component: Specials,
        beforeEnter: (a, x, next) => {
            if (!localStorage.getItem("authToken")) next({ name: 'Login', params: { unauthorized: true }})
            else next()
        }
    },
    {
        path: '/settings',
        name: 'Settings',
        component: Settings,
        beforeEnter: (a, x, next) => {
            if (!localStorage.getItem("authToken")) next({ name: 'Login', params: { unauthorized: true }})
            else next()
        }
    }
]

// 3. Create the router instance and pass the `routes` option
const router = createRouter({
    // 4. Provide the history implementation to use. 
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    let connection = new WebSocket(websocket())
    connection.open = function(event) {
        event.target.send(JSON.stringify({ type: 'Remove User Field Focus', image: localStorage.getItem("currentUserImage"), color: localStorage.getItem("currentUserColor") }));
    }
    next()
})



export default router;